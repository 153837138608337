import * as Contract from '@tableau/api-external-contract-js';
import { LogicalTableInfo } from '@tableau/api-internal-contract-js';

/**
 * Implementation of a logical table.
 * This does not follow the Impl pattern as it is just a property bag.
 */
export class LogicalTable implements Contract.LogicalTable {
  public constructor(private _logicalTable: LogicalTableInfo) { }

  public get id(): string {
    return this._logicalTable.id;
  }

  public get caption(): string {
    return this._logicalTable.caption;
  }
}
