// Declare this key type and export the NotificationId to make this behave like a string enum
export enum VerbId {
  ApplyCategoricalFilter = 'categorical-filter',
  ApplyRangeFilter = 'range-filter',
  ClearFilter = 'clear-filter',
  InitializeExtension = 'initialize-extension',
  GetDataSummaryData = 'get-summary-data',
  GetUnderlyingData = 'get-underlying-data',
  GetDataSourceData = 'get-datasource-data',
  SaveExtensionSettings = 'save-extension-settings',
  GetSelectedMarks = 'get-selected-marks',
  GetHighlightedMarks = 'get-highlighted-marks',
  GetParametersForSheet = 'get-parameters-for-sheet',
  FindParameter = 'find-parameter',
  ChangeParameterValue = 'change-parameter-value',
  ClearSelectedMarks = 'clear-selected-marks',
  SelectByValue = 'select-by-value',
  GetDataSources = 'get-data-sources',
  GetAllDataSources = 'get-all-data-sources',
  RefreshDataSource = 'refresh-data-source',
  GetFilters = 'get-filters',
  GetFieldAndDataSource = 'get-field-and-datasource',
  GetCategoricalDomain = 'get-categorical-domain',
  GetRangeDomain = 'get-range-domain',
  GetJoinDescription = 'get-join-description',
  GetConnectionDescriptionSummaries = 'get-connection-description-summaries',
  DisplayDialog = 'display-dialog',
  CloseDialog = 'close-dialog',
  SetClickThrough = 'set-click-through',
  TestConversionVerb = 'test-conversion-verb',
  GetField = 'get-field',
  GetDataSource = 'get-datasource',
  GetActiveTables = 'get-active-tables',
  SetZoneVisibility = 'set-zone-visibility',
  MoveAndResizeZones = 'move-and-resize-zones',
  ReplayAnimation = 'replay-animation',
  BlockExtension = 'block-extension',
  GetLogicalTables = 'get-logical-tables',
  GetLogicalTableData = 'get-logical-table-data',
  GetUnderlyingTableData = 'get-underlying-table-data',
  GetUnderlyingTables = 'get-underlying-tables',
  VizAPI = 'viz-api',
  GetFonts = 'get-fonts',
}
