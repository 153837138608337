import * as Contract from '@tableau/api-external-contract-js';
import { SheetPath } from '@tableau/api-internal-contract-js';

import { SheetInfoImpl } from './SheetInfoImpl';

import { ParametersService } from '../Services/ParametersService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { ErrorHelpers } from '../Utils/ErrorHelpers';
import { TableauError } from '../TableauError';
import { ParameterImpl } from './ParameterImpl';

export class SheetImpl {
  public constructor(protected _sheetInfoImpl: SheetInfoImpl, protected _registryId?: number) {
  }

  public get name(): string {
    return this._sheetInfoImpl.name;
  }

  public get sheetType(): Contract.SheetType {
    return this._sheetInfoImpl.sheetType;
  }

  public get sheetPath(): SheetPath {
    return this._sheetInfoImpl.sheetPath;
  }

  public get size(): Contract.Size | Contract.SheetSize {
    return this._sheetInfoImpl.sheetSize;
  }

  public get hidden(): boolean {
    if (this._sheetInfoImpl.isHidden !== undefined) {
      return this._sheetInfoImpl.isHidden;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError,
      `isHidden not implemented`);
  }

  public get active(): boolean {
    if (this._sheetInfoImpl.isActive !== undefined) {
      return this._sheetInfoImpl.isActive;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError,
      `active not implemented`);
  }

  public get index(): number {
    if (this._sheetInfoImpl.index !== undefined) {
      return this._sheetInfoImpl.index;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError,
      `index not ImplementationError`);
  }


  public findParameterAsync(parameterName: string): Promise<ParameterImpl | undefined> {
    ErrorHelpers.verifyParameter(parameterName, 'parameterName');

    const service = ApiServiceRegistry.get(this._registryId).getService<ParametersService>(ServiceNames.Parameters);
    return service.findParameterByNameAsync(parameterName);
  }

  public getParametersAsync(): Promise<Array<ParameterImpl>> {
    const service = ApiServiceRegistry.get(this._registryId).getService<ParametersService>(ServiceNames.Parameters);
    return service.getParametersForSheetAsync(this.sheetPath);
  }
}
