import * as Contract from '@tableau/api-external-contract-js';
import { EmbeddingBootstrapInfo, EmbeddingSheetInfo, SheetPath, SheetType, VisualId } from '@tableau/api-internal-contract-js';
import { DashboardImpl, SheetImpl, SheetInfoImpl, Size, TableauError, WorkbookImpl, WorksheetImpl } from '@tableau/api-shared-js';
import { SheetSize } from '../Models/SheetSize';

export class EmbeddingWorkbookImpl extends WorkbookImpl {
  private _name: string;
  private _publishedSheetsInfo: Array<SheetInfoImpl> = [];
  private _activeSheetImpl: SheetImpl;
  public constructor(bootstrapInfo: EmbeddingBootstrapInfo, private _registryId: number) {
    super();
    this._name = bootstrapInfo.workbookName;
    this.initializeWorkbook(bootstrapInfo);
  }

  public get activeSheet(): SheetImpl {
    return this._activeSheetImpl;
  }

  public get publishedSheetsInfo(): Array<SheetInfoImpl> {
    return this._publishedSheetsInfo;
  }

  public get name(): string {
    return this._name;
  }

  private initializeWorkbook(bootstrapInfo: EmbeddingBootstrapInfo): void {
    bootstrapInfo.publishedSheets.forEach((publishedSheet: EmbeddingSheetInfo, index: number) => {
      // ToDo: TFS1278952 duplicate sheetSize behavior from v2
      const sheetSize: Contract.SheetSize = new SheetSize(
        Contract.SheetSizeBehavior.Automatic,
        new Size(publishedSheet.sizeConstraint.minHeight, publishedSheet.sizeConstraint.minWidth),
        new Size(publishedSheet.sizeConstraint.maxHeight, publishedSheet.sizeConstraint.maxWidth),
      );

      const isActive = publishedSheet.name === bootstrapInfo.currWorksheetName;
      // Todo: TFS1284911 : isHidden is not always false. Verify is sheet is part of published sheets first
      const isHidden = false;

      let sheetType: Contract.SheetType;
      switch (publishedSheet.sheetType) {
        case SheetType.Worksheet: {
          sheetType = Contract.SheetType.Worksheet;
          break;
        }
        case SheetType.Dashboard: {
          sheetType = Contract.SheetType.Dashboard;
          break;
        }
        case SheetType.Story: {
          sheetType = Contract.SheetType.Story;
          break;
        }
        default: {
          throw new TableauError(Contract.EmbeddingErrorCodes.ServerError, 'Invalid SheetType');
        }
      }

      const sheetInfoImpl: SheetInfoImpl = new SheetInfoImpl(publishedSheet.name, sheetType, sheetSize, index, isActive, isHidden);

      this._publishedSheetsInfo.push(sheetInfoImpl);
      if (isActive) {
        switch (sheetInfoImpl.sheetType) {
          case Contract.SheetType.Worksheet: {
            const vizId: VisualId = {
              worksheet: sheetInfoImpl.name,
            };

            this._activeSheetImpl = new WorksheetImpl(sheetInfoImpl, vizId, undefined, this._registryId);
            break;
          }
          case Contract.SheetType.Dashboard: {
            const sheetPath: SheetPath = {
              sheetName: sheetInfoImpl.name,
              isDashboard: true,
            };

            this._activeSheetImpl = new DashboardImpl(sheetInfoImpl, bootstrapInfo.dashboardZones, sheetPath, this._registryId);
            break;
          }
          case Contract.SheetType.Story: {
            // Todo: 1278976 - Initialize viz with story point
            break;
          }
          default: {
            throw new TableauError(Contract.EmbeddingErrorCodes.ServerError, 'Invalid SheetType');
          }
        }
      }
    });
  }
}
