import * as Contract from '@tableau/api-external-contract-js';
import { FilterEvent, NotificationId, VisualId } from '@tableau/api-internal-contract-js';
import { ApiServiceRegistry, NotificationService, ServiceNames, TableauError } from '@tableau/api-shared-js';
import { FilterChangedEvent } from '../Events/FilterChangedEvent';
import { MarksSelectedEvent } from '../Events/MarksSelectedEvent';
import { VizImpl } from '../Impl/VizImpl';
import { Workbook } from '../Models/Workbook';

// https://html.spec.whatwg.org/multipage/custom-elements.html
// https://developers.google.com/web/fundamentals/web-components/best-practices
// https://www.w3.org/2001/tag/doc/webcomponents-design-guidelines/
// A starting point for best practices to follow

/**
 * This class is specifically focused on transferring information between html and viz
 * and giving the user an entry point into the viz model
 * It should have as little logic as possible
 */
export class TableauViz extends HTMLElement implements Contract.Viz {
  // localized strings copied over from Strings.AccessibilityDataVisualizationTitleAttr
  // TFS 1287423: Enable loc pipeline
  private static localizedTitles: Record<string, string> = {
    en: 'Data Visualization',
    'en-GB': 'Data Visualisation',
    fr: 'Visualisation de donn\u00E9es',
    es: 'Visualizaci\u00F3n de datos',
    it: 'Visualizzazione dati',
    pt: 'Visualiza\u00E7\u00E3o de dados',
    ja: '\u30C7\u30FC\u30BF \u30D3\u30B8\u30E5\u30A2\u30E9\u30A4\u30BC\u30FC\u30B7\u30E7\u30F3',
    de: 'Datenvisualisierung',
    ko: '\uB370\uC774\uD130 \uBE44\uC8FC\uC5BC\uB9AC\uC81C\uC774\uC158',
    'zh-CN': '\u6570\u636E\u53EF\u89C6\u5316',
    'zh-TW': '\u8CC7\u6599\u53EF\u8996\u5316',
  };

  public static VizAttributeDefaults = {
    width: '600px',
    height: '800px',
    device: Contract.DeviceType.Default,
    toolbar: Contract.Toolbar.Bottom,
  };

  private _connected = false;

  private _iframe: HTMLIFrameElement;
  private _vizImpl: VizImpl;

  // ========================================== Begin Custom Element definition ==========================================

  // https://html.spec.whatwg.org/multipage/custom-elements.html#custom-element-conformance
  public constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  //#region Reactions

  //#region Filters
  private readFiltersFromChild(): Contract.FilterParameters[] {
    const filters: Contract.FilterParameters[] = [];
    [].forEach.call(this.children, (child) => {
      if (
        child.localName === Contract.VizChildElements.VizFilter &&
        !!child.getAttribute(Contract.VizChildElementAttributes.Field) &&
        !!child.getAttribute(Contract.VizChildElementAttributes.Value)
      ) {
        filters.push({
          field: child.getAttribute(Contract.VizChildElementAttributes.Field),
          value: child.getAttribute(Contract.VizChildElementAttributes.Value),
        });
      }
    });
    return filters;
  }
  //#endregion Filters

  private readCustomParamsFromChildren(): Contract.CustomParameter[] {
    const params: Contract.CustomParameter[] = [];
    [].forEach.call(this.children, (child) => {
      if (
        child.localName === Contract.VizChildElements.CustomParameter &&
        !!child.getAttribute(Contract.VizChildElementAttributes.Name) &&
        !!child.getAttribute(Contract.VizChildElementAttributes.Value)
      ) {
        params.push({
          name: child.getAttribute(Contract.VizChildElementAttributes.Name),
          value: child.getAttribute(Contract.VizChildElementAttributes.Value),
        });
      }
    });

    return params;
  }

  public connectedCallback(): void {
    if (document.readyState === 'loading') {
      // Loading hasn't finished yet
      document.addEventListener('DOMContentLoaded', () => {
        this.initialize();
      });
    } else {
      // `DOMContentLoaded` has already fired
      this.initialize();
    }
  }

  private initialize(): void {
    if (!this._connected) {
      this.setupFrame();
      this.updateRendering();
      this._connected = true;
    }
  }

  public disconnectedCallback(): void {
    if (this._iframe) {
      this.shadowRoot?.removeChild(this._iframe);
    }
    this._vizImpl.dispose();
  }

  public static get observedAttributes(): string[] {
    // Take caution before adding to this list because for every observed attribute change
    // we unregister and re-render the viz
    return Object.values(Contract.VizAttributes);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    // if it's width/height, resize the frame
    // TFS 892487: Deal with sizing and scrollbars later
    if (name === Contract.VizAttributes.Width || name === Contract.VizAttributes.Height) {
      this.setFrameSize();
    }

    // When there is a change in the other observed attributes, let's unregister the Viz
    // and re-render the viz again with new attribute values
    if (this._connected) {
      // vizImpl is empty when a src is not set on initial tableau-viz load
      if (this._vizImpl) {
        this._vizImpl.dispose();
      }
      this.updateRendering();
    }
  }
  //#endregion Reaction

  private updateRendering(): void {
    // Nothing to render if the user hasn't provided a src
    if (this.src) {
      const vizqlOptions = this.constructVizqlOptions();
      this._vizImpl = new VizImpl(
        this,
        this._iframe,
        this.src,
        vizqlOptions,
        this.readFiltersFromChild(),
        this.readCustomParamsFromChildren(),
      );
      this._vizImpl.initializeViz();
      this.initializeEvents();
    } else {
      console.warn('A src needs to be set on the tableau-viz element. Skipping rendering.');
    }
  }

  private constructVizqlOptions(): Contract.VizSettings {
    const options: Contract.VizSettings = {
      disableUrlActionsPopups: this.disableUrlActionsPopups,
      hideTabs: this.hideTabs,
      toolbar: this.toolbar,
      instanceIdToClone: this.instanceIdToClone,
      device: this.device,
      token: this.token,
      touchOptimize: this.touchOptimize,
      debug: this.debug,
    };

    return options;
  }

  private initializeEvents(): void {
    let notificationService: NotificationService;

    try {
      notificationService = ApiServiceRegistry.get(this._vizImpl.embeddingId).getService<NotificationService>(ServiceNames.Notification);
    } catch (e) {
      // If we don't have this service registered, just return
      throw new TableauError(Contract.EmbeddingErrorCodes.EventInitializationError, 'Event initialization failed');
    }

    // Initialize all of the event managers we'll need (one for each event type)
    notificationService.registerHandler(
      NotificationId.SelectedMarksChanged,
      (model) => {
        const visualId = model as VisualId;
        return this.shouldNotifyEvent(visualId);
      },
      (visualId: VisualId) => {
        const event = new MarksSelectedEvent(this.getWorksheetForNotificationHandler(visualId));
        this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.MarkSelectionChanged, { detail: event }));
      },
    );

    notificationService.registerHandler(
      NotificationId.FilterChanged,
      (model) => {
        const visualId = (model as FilterEvent).visualId;
        return this.shouldNotifyEvent(visualId);
      },
      (event: FilterEvent) => {
        const filterChangeEvent = new FilterChangedEvent(this.getWorksheetForNotificationHandler(event.visualId), event.fieldName);
        this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.FilterChanged, { detail: filterChangeEvent }));
      },
    );
  }

  private shouldNotifyEvent(visualId: VisualId): boolean {
    switch (this.workbook.activeSheet.sheetType) {
      case Contract.SheetType.Worksheet:
        return this.workbook.activeSheet.name === visualId.worksheet;
      case Contract.SheetType.Dashboard: {
        const dashboard = this.workbook.activeSheet as Contract.EmbeddingDashboard;
        const length = dashboard.worksheets.filter((ws) => ws.name === visualId.worksheet).length;
        return length === 1;
      }
      case Contract.SheetType.Story:
      default:
        return false;
    }
  }

  private getWorksheetForNotificationHandler(visualId: VisualId): Contract.EmbeddingWorksheet {
    let worksheet: Contract.EmbeddingWorksheet;

    switch (this.workbook.activeSheet.sheetType) {
      case Contract.SheetType.Worksheet:
        worksheet = this.workbook.activeSheet as Contract.EmbeddingWorksheet;
        break;
      case Contract.SheetType.Dashboard: {
        const dashboard = this.workbook.activeSheet as Contract.EmbeddingDashboard;
        const worksheetArr = dashboard.worksheets.filter((ws) => ws.name === visualId.worksheet);
        if (worksheetArr.length === 1) {
          worksheet = worksheetArr[0];
        } else {
          throw new TableauError(Contract.EmbeddingErrorCodes.IndexOutOfRange, 'Worksheet not found');
        }
        break;
      }
      case Contract.SheetType.Story:
      default:
        throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, 'Could not find sheetType');
    }

    return worksheet;
  }

  private setupFrame(): void {
    this._iframe = document.createElement('iframe');

    const lang = navigator.language;
    const localizedTitle =
      TableauViz.localizedTitles[lang] || TableauViz.localizedTitles[lang.substr(0, 2)] || TableauViz.localizedTitles.en;
    // give context to users using screenreaders as to what kind of iframe they've entered
    this._iframe.setAttribute('title', localizedTitle);

    this._iframe.setAttribute('allowTransparency', 'true');
    this._iframe.setAttribute('allowFullScreen', 'true');

    // reset any box model styles
    this._iframe.style.margin = '0px';
    this._iframe.style.padding = '0px';
    this._iframe.style.border = 'none';
    this._iframe.style.position = 'relative';

    // inherit sizing from parent element
    this._iframe.style.minWidth = '100%';
    this._iframe.style.minHeight = '100%';

    // set iframe name & id
    this._iframe.id = this.id;
    this._iframe.name = this.id;

    this.setFrameSize();

    if (this.shadowRoot) {
      this.shadowRoot.appendChild(this._iframe);
    }
  }

  private setFrameSize(): void {
    if (this._iframe) {
      this._iframe.style.height = this.height;
      this._iframe.style.width = this.width;
    }
  }

  //#region Simple Getters / Setters

  public get src(): string | null {
    return this.getAttribute(Contract.VizAttributes.Src);
  }

  public set src(v: string | null) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.Src, v);
    }
  }

  private getPixelAttributeOrDefault(attributeName: string, defaultValue: string): string {
    const attr = this.getAttribute(attributeName);
    if (attr && attr !== '') {
      return isNaN(Number(attr)) ? attr : `${Math.round(Number(attr))}px`;
    } else {
      // if it was invalid css, it will be blank
      return defaultValue;
    }
  }

  public get width(): string {
    return this.getPixelAttributeOrDefault(Contract.VizAttributes.Width, TableauViz.VizAttributeDefaults.width);
  }

  // non-valid css lengths will simply turn into '' e.g a number with no units
  public set width(v: string) {
    this.setAttribute(Contract.VizAttributes.Width, v);
  }

  public get height(): string {
    return this.getPixelAttributeOrDefault(Contract.VizAttributes.Height, TableauViz.VizAttributeDefaults.height);
  }

  // non-valid css lengths will simply turn into '' e.g a number with no units
  public set height(v: string) {
    this.setAttribute(Contract.VizAttributes.Height, v);
  }

  public get disableUrlActionsPopups(): boolean {
    return this.hasAttribute(Contract.VizAttributes.DisableUrlActionsPopups);
  }

  public set disableUrlActionsPopups(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.DisableUrlActionsPopups, '');
    } else {
      this.removeAttribute(Contract.VizAttributes.DisableUrlActionsPopups);
    }
  }

  public get hideTabs(): boolean {
    return this.hasAttribute(Contract.VizAttributes.HideTabs);
  }

  public set hideTabs(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.HideTabs, '');
    } else {
      this.removeAttribute(Contract.VizAttributes.HideTabs);
    }
  }

  public get toolbar(): Contract.Toolbar {
    const toolbarKey = attributeToEnumKey(this.getAttribute(Contract.VizAttributes.Toolbar));
    const position = Contract.Toolbar[toolbarKey];
    if (!position) {
      return TableauViz.VizAttributeDefaults.toolbar;
    }

    return position;
  }

  public set toolbar(v: Contract.Toolbar) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.Toolbar, v);
    }
  }

  public get instanceIdToClone(): string | undefined {
    const idToClone = this.getAttribute(Contract.VizAttributes.InstanceIdToClone);
    if (!idToClone) {
      return undefined;
    }

    return idToClone;
  }

  public set instanceIdToClone(v: string | undefined) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.InstanceIdToClone, v);
    } else {
      this.removeAttribute(Contract.VizAttributes.InstanceIdToClone);
    }
  }

  public get device(): Contract.DeviceType {
    const deviceKey = attributeToEnumKey(this.getAttribute(Contract.VizAttributes.Device));
    const device = Contract.DeviceType[deviceKey];
    if (!device) {
      return TableauViz.VizAttributeDefaults.device; // it was not a valid device type
    }

    return device;
  }

  public set device(v: Contract.DeviceType) {
    this.setAttribute(Contract.VizAttributes.Device, v);
  }

  public get token(): string | undefined {
    const tokenValue = this.getAttribute(Contract.VizAttributes.Token);

    if (!tokenValue) {
      return undefined;
    }

    return tokenValue;
  }

  public set token(v: string | undefined) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.Token, v);
    } else {
      this.removeAttribute(Contract.VizAttributes.Token);
    }
  }

  public get touchOptimize(): boolean {
    return this.hasAttribute(Contract.VizAttributes.TouchOptimize);
  }

  public set touchOptimize(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.TouchOptimize, '');
    } else {
      this.removeAttribute(Contract.VizAttributes.TouchOptimize);
    }
  }

  public get debug(): boolean {
    return this.hasAttribute(Contract.VizAttributes.Debug);
  }

  public set debug(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.Debug, '');
    } else {
      this.removeAttribute(Contract.VizAttributes.Debug);
    }
  }

  //#endregion

  //#region For testing

  public get iframe(): HTMLIFrameElement {
    return this._iframe;
  }

  //#endregion

  // ========================================== End Custom Element definition ============================================

  // =========================================== Begin Viz Model definiton ===============================================

  public get automaticUpdatesArePaused(): boolean {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public pauseAutomaticUpdatesAsync(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public resumeAutomaticUpdatesAsync(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public toggleAutomaticUpdatesAsync(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public revertAllAsync(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public refreshDataAsync(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public showDownloadWorkbookDialog(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public showExportImageDialog(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public showExportPDFDialog(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public showExportDataDialog(worksheetInDashboard: Contract.EmbeddingWorksheet | Contract.SheetInfo | string): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public showExportCrossTabDialog(worksheetInDashboard: Contract.EmbeddingWorksheet | Contract.SheetInfo | string): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public showShareDialog(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public getCurrentSrcAsync(): Promise<string> {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public redoAsync(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public undoAsync(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  /**
   * This is the public entry point for users to get a reference to the whole data model
   */

  public get workbook(): Contract.EmbeddingWorkbook {
    return new Workbook(this._vizImpl.workbookImpl);
  }

  // =========================================== End Viz Model definiton =================================================
}

// This maybe needed in mutlipe files, so leaving outside the class for now.
export function attributeToEnumKey(value: string | null): string {
  if (!value || value.length < 1) {
    return '';
  }

  const lowercase = value.toLowerCase();
  const firstUpper = lowercase[0].toUpperCase() + lowercase.substring(1);
  return firstUpper;
}
