import { ServiceImplBase } from './ServiceImplBase';
import { ServiceNames } from '../ServiceRegistry';
import { VizService } from '../VizService';
import {
  ExecuteParameters,
  ParameterId,
  VerbId
} from '@tableau/api-internal-contract-js';

export class VizServiceImpl extends ServiceImplBase implements VizService {
  public get serviceName(): string {
    return ServiceNames.Viz;
  }

  public createVizImageAsync(inputSpec: object): Promise<string> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'createVizImageAsync',
      [ParameterId.VizAPIInputJson]: JSON.stringify(inputSpec)
    };

    return this.execute(VerbId.VizAPI, parameters).then(response => {
      const result = response.result as string;
      return result;
    });
  }
}
