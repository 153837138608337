import { ErrorCodes } from '@tableau/api-external-contract-js';
import { TableauError } from '../TableauError';
import { ErrorHelpers } from './ErrorHelpers';

export class Param {
  /**
   * serializes the date into the format that the server expects.
   * @param date the date to serialize
   */
  public static serializeDateForPlatform(date: Date): string {
    const year: number = date.getUTCFullYear();
    const month: number = date.getUTCMonth() + 1;
    const day: number = date.getUTCDate();
    const hh: number = date.getUTCHours();
    const mm: number = date.getUTCMinutes();
    const sec: number = date.getUTCSeconds();
    return `${year}-${month}-${day} ${hh}:${mm}:${sec}`;
  }

  public static serializeBooleanForPlatform(bool: boolean): string {
    return bool ? 'true' : 'false';
  }

  public static serializeNumberForPlatform(num: number): string {
    return num.toString(10);
  }

  /**
   * Verifies the input is a number
   */
  /* tslint:disable:no-any */
  public static isTypeNumber(input: any): boolean {
    return typeof (input) === 'number' || input instanceof Number;
  }
  /* tslint:enable:no-any */

  /**
   * Verifies the input is a Date
   */
  /* tslint:disable:no-any */
  public static isTypeDate(input: any): boolean {
    return input instanceof Date;
  }
  /* tslint:enable:no-any */

  /* tslint:disable-next-line:no-any */
  public static isTypeString(input: any): boolean {
    return typeof (input) === 'string' || input instanceof String;
  }

  /* tslint:disable-next-line:no-any */
  public static isTypeBool(input: any): boolean {
    return typeof (input) === 'boolean' || input instanceof Boolean;
  }

  /* tslint:disable-next-line:no-any */
  public static serializeParameterValue(value: any): string {
    if (Param.isTypeNumber(value)) {
      return Param.serializeNumberForPlatform(value as number);
    } else if (Param.isTypeDate(value)) {
      return Param.serializeDateForPlatform(value as Date);
    } else if (Param.isTypeBool(value)) {
      return Param.serializeBooleanForPlatform(value as boolean);
    } else if (Param.isTypeString(value)) {
      return value;
    } else {
      throw new TableauError(ErrorCodes.InternalError, `Unexpected invalid value for: ${value}`);
    }
  }

  /**
   * Verifies that the parameter is already a Map, or converts an object to a Map.
   * If the parameter is an object, we convert it to a Map using Object.entries.
   * @throws if the parameter is not an object or Map
   */
  // tslint:disable-next-line:no-any
  public static convertParameterToMap(param: object, paramName: string): Map<any, any> {
    ErrorHelpers.verifyParameterType(param, 'object', paramName);
    if (Array.isArray(param)) {
      throw new TableauError(ErrorCodes.InvalidParameter, `Expecting object or Map for: ${paramName}.`);
    }

    return param instanceof Map ? param : new Map(Object.entries(param));
  }
}
