import { DashboardLayoutChange, DashboardLayoutChangeDetails, SheetType } from '@tableau/api-external-contract-js';
import * as Contract from '@tableau/api-external-contract-js';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import {
  DashboardObjectType,
  DashboardZone,
  SheetPath,
  VisualId
} from '@tableau/api-internal-contract-js';
import { InternalToExternalEnumMappings } from '../EnumMappings/InternalToExternalEnumMappings';
import { Point } from '../Point';
import { SheetImpl } from './SheetImpl';
import { SheetInfoImpl } from './SheetInfoImpl';
import { ZoneService } from '../Services/ZoneService';
import { AnimationService } from '../Services/AnimationService';
import { Size } from '../Size';
import { WorksheetImpl } from './WorksheetImpl';
import { DashboardObjectImpl } from './DashboardObjectImpl';

export class DashboardImpl extends SheetImpl {
  private _worksheetsImpl: Array<WorksheetImpl>;
  private _objects: Array<DashboardObjectImpl>;
  private zoneMap: Map<number, DashboardObjectImpl>;

  public constructor(
    _sheetInfo: SheetInfoImpl,
    private _zones: Array<DashboardZone>,
    private _sheetPath: SheetPath,
    _registryId?: number,
  ) {
    super(_sheetInfo, _registryId);
  }


  public get worksheetsImpl(): Array<WorksheetImpl> {
    return this._worksheetsImpl;
  }

  public get objects(): Array<DashboardObjectImpl> {
    return this._objects;
  }

  public initializeWithPublicInterfaces(): void {

    this._worksheetsImpl = new Array<WorksheetImpl>();
    this._objects = new Array<DashboardObjectImpl>();
    this.zoneMap = new Map<number, DashboardObjectImpl>();

    // Process all the zones which are contained in this dashboard
    for (const zone of this._zones) {
      let worksheetImpl: WorksheetImpl | undefined = undefined;

      const zoneSize = new Size(zone.height, zone.width);
      // As the dashboard is active, all over zones in the dashboard are inactive.
      const isActive = false;
      // Todo: TFS1284911 : isHidden is not always false. Verify is sheet is part of published sheets first
      const isHidden = false;

      if (zone.zoneType === DashboardObjectType.Worksheet) {
        // zone.sheetInfo was not initialized prior to internal-contract 1.6.0
        const worksheetName = zone.sheetInfo ? zone.sheetInfo.name : zone.name;
        let sheetInfoImpl;
        // Indexes, isActive and some more properties in sheetInfoImpl are embedding specific.
        // But we init them for both extensions and embedding as the Models will only use what is relevant.
        sheetInfoImpl = new SheetInfoImpl(
          worksheetName,
          SheetType.Worksheet,
          zoneSize,
          this._worksheetsImpl.length,
          isActive,
          isHidden);


        const vizId: VisualId = {
          worksheet: worksheetName,
          dashboard: this._sheetInfoImpl.name,
          storyboard: this._sheetPath.storyboard,
          flipboardZoneID: this._sheetPath.flipboardZoneID,
          storyPointID: this._sheetPath.storyPointID
        };

        worksheetImpl = new WorksheetImpl(sheetInfoImpl, vizId, this);
        this._worksheetsImpl.push(worksheetImpl);
      }

      const zonePoint = new Point(zone.x, zone.y);

      const dashboardObjectImpl = new DashboardObjectImpl(
        this,
        InternalToExternalEnumMappings.dashboardObjectType.convert(zone.zoneType),
        zonePoint,
        zoneSize,
        worksheetImpl,
        zone.name,
        (zone.isFloating !== undefined) ? zone.isFloating : false,  // before 1.6.0 we didn't have isFloating, so we assume false
        (zone.isVisible !== undefined) ? zone.isVisible : true,     // before 1.6.0 we didn't have isVisible, so we assume true
        zone.zoneId
      );

      this._objects.push(dashboardObjectImpl);
      this.zoneMap.set(zone.zoneId, dashboardObjectImpl);
    }
  }

  public setDashboardObjectVisibilityAsync(dashboardObjectVisibilityMap: Contract.DashboardObjectVisibilityMap | object): Promise<void> {
    const zoneService = ApiServiceRegistry.get(this._registryId).getService<ZoneService>(
      ServiceNames.Zone);

    return zoneService.setVisibilityAsync(/*Dashboard Name*/ this.name, this.zoneMap, dashboardObjectVisibilityMap);
  }

  public getDashboardObjectById(dashboardObjectId: number): DashboardObjectImpl | undefined {
    return this.zoneMap.get(dashboardObjectId);
  }

  public updateZones(newZones: Array<DashboardZone>): DashboardLayoutChangeDetails {
    // getting previous dashboard objects
    const oldDashboardObjects = this._objects;
    const oldZoneMap = this.zoneMap;
    // updating zones, and reinitializing instance variables
    this._zones = newZones;
    this.initializeWithPublicInterfaces();
    // getting new dashboard objects
    const newDashboardObjects = this._objects;
    const newZoneMap = this.zoneMap;
    // initializing map for changes
    const zoneChanges: DashboardLayoutChangeDetails = new Map();

    // comparing old dashboard objects with new ones
    oldDashboardObjects.forEach((oldObject) => {
      const oldId: number = oldObject!.id;

      // checking if zone was removed
      if (!newZoneMap.has(oldId)) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.Removed);
        return;
      }

      const newObject = newZoneMap.get(oldId);
      if (oldObject!.isFloating !== newObject!.isFloating) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.IsFloatingChanged);
      }

      if (oldObject!.isVisible !== newObject!.isVisible) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.IsVisibleChanged);
      }

      if (oldObject!.name !== newObject!.name) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.NameChanged);
      }

      if (oldObject!.position!.x !== newObject!.position!.x ||
        oldObject!.position!.y !== newObject!.position!.y) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.PositionChanged);
      }

      if (oldObject!.size!.height !== newObject!.size!.height ||
        oldObject!.size!.width !== newObject!.size!.width) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.SizeChanged);
      }
    });

    // Checking for any added zones
    newDashboardObjects.forEach((newObject) => {
      if (!oldZoneMap.has(newObject.id)) {
        this.addChange(newObject.id, zoneChanges, DashboardLayoutChange.Added);
      }
    });

    return zoneChanges;
  }

  private addChange(zoneId: number, zoneChanges: DashboardLayoutChangeDetails, change: DashboardLayoutChange): void {
    if (!zoneChanges.has(zoneId)) {
      zoneChanges.set(zoneId, []);
    }

    zoneChanges.get(zoneId)!.push(change);
  }

  public moveAndResizeDashboardObjectsAsync(dashboardObjectPositionAndSizeUpdateArray:
    Contract.DashboardObjectPositionAndSizeUpdateArray): Promise<void> {
    const zoneService = ApiServiceRegistry.get(this._registryId).getService<ZoneService>(
      ServiceNames.Zone);

    return zoneService.moveAndResizeAsync(/*Dashboard Name*/ this.name, this.zoneMap,
      dashboardObjectPositionAndSizeUpdateArray);
  }

  public replayAnimationAsync(replaySpeed: Contract.ReplaySpeedType): Promise<void> {
    const animationService = ApiServiceRegistry.get(this._registryId).getService<AnimationService>(
      ServiceNames.Animation);

    return animationService.replayAsync(replaySpeed);
  }
}
