import * as Contract from '@tableau/api-external-contract-js';
import { DashboardImpl } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { DashboardObject } from './DashboardObject';
import { Sheet } from './Sheet';
import { Worksheet } from './Worksheet';

export class Dashboard extends Sheet implements Contract.EmbeddingDashboard {
  public constructor(private _dashboardImpl: DashboardImpl, workbookImpl: EmbeddingWorkbookImpl) {
    super(_dashboardImpl, workbookImpl);
    _dashboardImpl.initializeWithPublicInterfaces();
  }

  public get objects(): Array<Contract.EmbeddingDashboardObject> {
    return this._dashboardImpl.objects.map((object) => {
      return new DashboardObject(object, this._workbookImpl);
    });
  }

  public get worksheets(): Array<Contract.EmbeddingWorksheet> {
    return this._dashboardImpl.worksheetsImpl.map((object) => {
      return new Worksheet(object, this._workbookImpl);
    });
  }
}
