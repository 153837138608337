export enum ParameterId {
  ExtensionLocator = 'extension-locator',
  ExtensionBootstrapInfo = 'extension-bootstrap-info',
  ExtensionSettingsInfo = 'extension-settings-info',
  ExtensionZoneId = 'extension-zone-id',
  VisualId = 'visual-id',
  SheetPath = 'sheet-path',
  ColumnsToIncludeById = 'columns-to-include-by-id',
  IgnoreAliases = 'ignore-aliases',
  IgnoreSelection = 'ignore-selection',
  IncludeAllColumns = 'include-all-columns',
  MaxRows = 'max-rows',
  UnderlyingDataTable = 'underlying-data-table',
  UnderlyingSummaryDataTable = 'underlying-summary-data-table',
  DataSourceDataTable = 'data-source-data-table',
  ShowDataTableFormat = 'show-data-table-format',

  SettingsValues = 'settings-values',
  SelectedData = 'selected-data',
  HighlightedData = 'highlighted-data',

  // Filter Params
  FieldName = 'field-name',
  FilterValues = 'filter-values',
  FilterUpdateType = 'filter-update-type',
  IsExcludeMode = 'is-exclude',
  FilterRangeMin = 'filter-range-min',
  FilterRangeMax = 'filter-range-max',
  FilterRangeNullOption = 'filter-range-null-option',
  WorksheetFilters = 'worksheet-filters',
  FieldId = 'field-id',
  DomainType = 'domain-type',
  CategoricalDomain = 'categorical-domain',
  QuantitativeDomain = 'quantitative-dmain',
  Field = 'field',

  WorksheetName = 'worksheet-name',
  DashboardName = 'dashboard',

  ParameterInfo = 'parameter-info',
  ParameterInfos = 'parameter-infos',
  ParameterCaption = 'paremeter-caption',
  ParameterFieldName = 'parameter-field-name',
  ParameterValue = 'parameter-value',
  Selection = 'selection',
  SelectionUpdateType = 'selectionUpdateType',
  HierValSelectionModels = 'hierarchicalValueSelectionModels',
  QuantRangeSelectionModels = 'quantativeRangeSelectionModels',
  DimValSelectionModels = 'dimensionValueSelectionModels',

  ActiveTablesInfo = 'active-tables-info',
  DataSource = 'data-source',
  DataSourceId = 'data-source-id',
  DeltaTimeMs = 'delta-time-ms',
  ShouldRefreshDS = 'should-refresh-ds',
  DataSchema = 'data-schema',
  DataSourceName = 'data-source-name',
  ColumnsToInclude = 'columns-to-include',
  JoinDescription = 'join-description',
  ConnectionDescriptionSummaries = 'connection-description-summaries',

  ExtensionDialogUrl = 'extension-dialog-url',
  ExtensionDialogPayload = 'extension-dialog-payload',
  IsExtensionDialog = 'is-extension-dialog',
  ExtensionDialogH = 'extension-dialog-height',
  ExtensionDialogW = 'extension-dialog-width',
  ExtensionDialogResult = 'extension-dialog-result',

  ExtensionContextMenuIds = 'extension-context-menu-ids',

  TestConversionParameter = 'test-conversion-parameter',

  Dashboard = 'dashboard',
  ZoneIdsVisibilityMap = 'zone-ids-visibility-map',
  DashboardObjectPositionAndSizeUpdate = 'dashboard-object-position-and-size-update',
  DashboardObjectPositionAndSizeUpdateList = 'dashboard-object-position-and-size-update-list',

  ReplaySpeed = 'replay-speed',

  ClickThroughEnabled = 'click-through-enabled',

  LogicalTableInfoList = 'logical-table-info-list',
  LogicalTableId = 'logical-table-id',

  VizAPIInputJson = 'vizapi-input-json',
  VizAPISVG = 'vizapi-svg',

  FunctionName = 'function-name',

  FontNameListItems = 'font-name-list-items',
}
