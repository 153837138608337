import { TableauViz } from './Components/TableauViz';

export class VizManager {
  // This contains a static counter & a map that keeps track of the vizzes embedded.
  private static embeddingIndex = 0;
  private static vizzes: Map<number, TableauViz> = new Map<number, TableauViz>();

  public static registerViz(viz: TableauViz): number {
    const assignedId = this.embeddingIndex;
    this.vizzes.set(assignedId, viz);
    this.embeddingIndex++;
    return assignedId;
  }

  public static unregisterViz(embeddingIndex: number): void {
    this.vizzes.delete(embeddingIndex);
  }

  // Methods below only used for testing
  public static getVizzes(): Map<number, TableauViz> {
    const vizzesCloned = new Map<number, TableauViz>();
    // a shallow copy is good enough
    this.vizzes.forEach((viz: TableauViz, embeddingIndex: number) => {
      vizzesCloned.set(embeddingIndex, viz);
    });
    return vizzesCloned;
  }
}
