/**
 * This is your main. This is where you re-export everything you want to be publicly available.
 *
 * The build enforces that the file has the same name as the global variable that is exported.
 */

// Due to the way we configured webpack, we should be exporting things which will be under
// a global variable called "tableau". Export everything we want to be visible under tableau
// from this file.

import { ApiVersion } from '@tableau/api-shared-js';
import { TableauViz } from './EmbeddingApi/Components/TableauViz';

declare let VERSION_IS_ALPHA: boolean;
const isAlpha: boolean = typeof VERSION_IS_ALPHA !== 'undefined' ? VERSION_IS_ALPHA : false;

declare let API_VERSION_NUMBER: string;
ApiVersion.SetVersionNumber(typeof API_VERSION_NUMBER !== 'undefined' ? API_VERSION_NUMBER : '0.0.0', isAlpha);

window.customElements.define('tableau-viz', TableauViz);
