import { SheetSize as EmbeddingSheetSize, Size as ExtensionSheetSize, SheetType } from '@tableau/api-external-contract-js';
import { SheetPath } from '@tableau/api-internal-contract-js';

export class SheetInfoImpl {
  public constructor(
    private _name: string,
    private _sheetType: SheetType,
    private _sheetSize: ExtensionSheetSize | EmbeddingSheetSize,
    private _index?: number,
    private _isActive?: boolean,
    private _isHidden?: boolean
  ) { }

  public get name(): string {
    return this._name;
  }

  public get sheetSize(): ExtensionSheetSize | EmbeddingSheetSize {
    return this._sheetSize;
  }

  public get sheetType(): SheetType {
    return this._sheetType;
  }

  public get sheetPath(): SheetPath {
    return {
      sheetName: this.name,
      isDashboard: this.sheetType === SheetType.Dashboard
      // TODO - Stories
    };
  }

  public get index(): number | undefined {
    return this._index;
  }

  public get isActive(): boolean | undefined {
    return this._isActive;
  }

  public get isHidden(): boolean | undefined {
    return this._isHidden;
  }
}
