export enum NotificationId {
  SelectedMarksChanged = 'selected-marks-changed',
  ParameterChanged = 'parameter-changed',
  FilterChanged = 'filter-changed',
  ExtensionDialogUpdate = 'extension-dialog-update',
  SettingsChanged = 'settings-changed',
  ContextMenuClick = 'context-menu-click',
  TestConversionNotification = 'test-conversion-notification',
  FirstVizSizeKnown = 'first-viz-size-known',
  VizInteractive = 'viz-interactive',
  WorkbookFormattingChanged = 'workbook-formatting-changed',
  DashboardLayoutChanged = 'dashboard-layout-changed',
}
