import {
  ApiShowDataTableSentinel,
  DataHeader,
  DataTable,
  DataTypeConverter,
  ExecuteParameters,
  ExecuteResponse,
  InternalTableauError,
  Notification,
  ParameterId,
  SelectedMarksTable,
  UnderlyingDataTable,
  VerbId
} from '../../JsApiInternalContract';

// tslint:disable:no-any

/** This function is called when we receive newer version and parameters from the external before we send it to platform */
export type DowngradeExecuteCall =
  (verb: VerbId, parameters: ExecuteParameters) => { verb: VerbId; parameters: ExecuteParameters };

/** This function is called when we receive a response back from platform and we need to upgrade it to external's version
 *  The verb and parameters provide a context to decide how to handle the response.
*/
export type UpgradeExecuteReturn =
  (executeResponse: ExecuteResponse, verb: VerbId, parameters: ExecuteParameters) => ExecuteResponse;

/** This function is called when we receive a notification from platform and we need to upgrade it to external's version */
export type UpgradeNotification =
  (notification: Notification) => Notification;

// 1.2 -> 1.0 Translations
// Uncomment this line to import from the V1 definition of the API
// import * as V1 from '@tableau-api-internal-contract-js_v1';

/**
 * Prior to 2019.2 (internal-contract v1.9), DataValue.value were all strings.
 * Go through all DataValue objects. If we have a string, but the type should not be a string,
 * convert it to the correct type. The type of DataValue.value is 'any' in the contract, so
 * this change doesn't need any updates to classes or types.
*/
export function UpgradeDataTableTypes(executeResponse: ExecuteResponse, verb: VerbId, parameters: ExecuteParameters): ExecuteResponse {
  if (!executeResponse) {
    return executeResponse;
  }

  let oldUnderlyingDataTable = executeResponse.result as UnderlyingDataTable;
  if (oldUnderlyingDataTable.data !== undefined && oldUnderlyingDataTable.isSummary !== undefined) {
    convertDataValues(oldUnderlyingDataTable.data);
    return executeResponse;
  }

  let oldSelectedMarksTable = executeResponse.result as SelectedMarksTable;
  if (oldSelectedMarksTable.data !== undefined && Array.isArray(oldSelectedMarksTable.data)) {
    oldSelectedMarksTable.data.forEach(marksTable => {
      convertDataValues(marksTable);
    });
    return executeResponse;
  }

  return executeResponse;
}

/**
 * Prior to 2020.2 (internal-contract v1.13 and older), worksheet.getUnderlyingTableDataAsync doesn't exist.
 * Map it to the older GetUnderlyingData verb and validate the the logical table Id.
 **/
export function DowngradeUnderlyingTableDataAsync(verb: VerbId, parameters: ExecuteParameters): {
  verb: VerbId;
  parameters: ExecuteParameters
} {
  if (verb === VerbId.GetUnderlyingTableData) {
    validateParametersForObjectModel(verb, parameters);
    verb = VerbId.GetUnderlyingData;
  }
  return {
    verb: verb,
    parameters: parameters
  };
}

/**
 * Prior to 2020.2 (internal-contract v1.13 and older), datasource.getLogicalTableDataAsync doesn't exist.
 * Map it to the older GetDataSourceData verb and validate the the logical table Id.
 **/
export function DowngradeLogicalTableDataAsync(verb: VerbId, parameters: ExecuteParameters): {
  verb: VerbId;
  parameters: ExecuteParameters
} {
  if (verb === VerbId.GetLogicalTableData) {
    validateParametersForObjectModel(verb, parameters);
    verb = VerbId.GetDataSourceData;
  }
  return {
    verb: verb,
    parameters: parameters
  };
}

function convertDataValues(table: DataTable): void {
  // dataTable is a two-dimensional array of data. First index is the row, second is the column.
  if (table === undefined || table.dataTable === undefined || !Array.isArray(table.dataTable)) {
    return;
  }

  table.dataTable.forEach(row => {
    row.forEach((dataValue, columnIndex) => {
      let value = dataValue.value;
      if (value !== null) {
        dataValue.value = DataTypeConverter.convertValueAsStringToValue(value, table.headers[columnIndex].dataType);
      }
    });
  });
}

// ToDo: TFS1069027 Refactor input verbs & parameters in api-internal-contract Upgrade/Downgrade framework
function validateParametersForObjectModel(verb: VerbId, parameters: ExecuteParameters): void {
  if (parameters[ParameterId.LogicalTableId] !== ApiShowDataTableSentinel.SingleTableId) {
    throw new Error(`Invalid logical table id passed to ${verb}.`) as InternalTableauError;
  }
}

/**
 * Prior to 2021.2 (internal-contract v1.29 and below), getSummaryDataAsync did not support maxRows,
 * and any get...DataAsync did not support columnsToIncludeById.
 * Trim result to maxRows if it is included in the parameters for getSummaryDataAsync
 * Trim columns to columnsToInclude if included in the parameters for any get...DataAsync
*/
export function UpgradeDataTableRowsAndColumns(
  executeResponse: ExecuteResponse,
  verb: VerbId, parameters: ExecuteParameters): ExecuteResponse {
  if (isGetSummaryDataVerb(verb) && parameters[ParameterId.MaxRows]) {
    executeResponse = adjustDataRowLength(executeResponse, parameters[ParameterId.MaxRows] as number);
  }

  if (isGetTableDataVerb(verb) && parameters[ParameterId.ColumnsToIncludeById]) {
    executeResponse = adjustDataColumns(executeResponse, parameters[ParameterId.ColumnsToIncludeById] as string[]);
  }

  return executeResponse;
}

function isGetSummaryDataVerb(verb: VerbId): boolean {
  return verb === VerbId.GetDataSummaryData;
}

function isGetTableDataVerb(verb: VerbId): boolean {
  return verb === VerbId.GetDataSummaryData
    || verb === VerbId.GetUnderlyingTableData
    || verb === VerbId.GetLogicalTableData
    || verb === VerbId.GetDataSourceData
    || verb === VerbId.GetUnderlyingData;
}

function adjustDataRowLength(executeResponse: ExecuteResponse, maxRows: number): ExecuteResponse {
  let underlyingDataTable = executeResponse.result as UnderlyingDataTable;
  if (!underlyingDataTable.data || !Array.isArray(underlyingDataTable.data.dataTable)) {
    return executeResponse;
  }

  if (maxRows > 0 && maxRows < underlyingDataTable.data.dataTable.length) {
    underlyingDataTable.data.dataTable.length = maxRows;
  }
  return executeResponse;
}

function adjustDataColumns(executeResponse: ExecuteResponse, columnsToInclude: string[]): ExecuteResponse {
  if (columnsToInclude.length === 0) {
    return executeResponse;
  }

  // verify that we have a valid UnderlyingDataTable
  let underlyingDataTable = executeResponse.result as UnderlyingDataTable;
  if (!underlyingDataTable.data || !Array.isArray(underlyingDataTable.data.dataTable) || !Array.isArray(underlyingDataTable.data.headers)) {
    return executeResponse;
  }

  // 1. filter the headers to only columnsToInclude
  // 2. use the new headers to filter all rows
  // 3. update the indices in our new headers
  let newHeaders: DataHeader[]
    = underlyingDataTable.data.headers.filter((header, index) => columnsToInclude.find(name => name === header.fieldName));
  underlyingDataTable.data.dataTable.forEach((row, index) => {
    underlyingDataTable.data.dataTable[index]
      = row.filter((value, valueIndex) => newHeaders.findIndex(header => header.index === valueIndex) !== -1);
  });
  let newIndex = 0;
  underlyingDataTable.data.headers = newHeaders.map(header => { header.index = newIndex++; return header; });

  return executeResponse;
}
