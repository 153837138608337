import { CustomParameter, EmbeddingErrorCodes, FilterParameters, Toolbar, VizSettings } from '@tableau/api-external-contract-js';
import { INTERNAL_CONTRACT_VERSION, VizOptionNames } from '@tableau/api-internal-contract-js';
import { ApiVersion, TableauError } from '@tableau/api-shared-js';

/**
 * This class should be the only one in api-embedding to contain any knowledge of how to construct a url for vizql
 * including what parameters can be sent and what values they can have or will default to.
 */
export class VizUrl {
  private static yes = 'y';
  private static no = 'n';
  private static publicHostName = 'public.tableau.com';
  private static authenticationPath = '/vizportal/api/web/v1/auth/embed/target';
  private static tokenParamName = 'token';
  private static viewParamName = 'target';

  public static buildUrl(
    urlStr: string,
    vizOptions: VizSettings,
    embeddingId: number,
    filters: FilterParameters[],
    customParams: CustomParameter[],
  ): URL {
    let url: URL;
    try {
      // strip params in URL, all custom params should come through VizSetttings, FilterParameters, CustomParameter
      url = new URL(urlStr.split('?')[0]);
    } catch (error) {
      throw new TableauError(EmbeddingErrorCodes.InvalidUrl, (error as Error).message);
    }

    const params = VizUrl._createDefaultParameters(url, embeddingId);
    VizUrl._appendDefaultParametersToUrl(url, params);
    VizUrl._appendUserOptionsToUrl(url, vizOptions);
    VizUrl._appendFiltersToUrl(url, filters);
    VizUrl._appendCustomParamsToUrl(url, customParams);

    if (vizOptions.token) {
      url = VizUrl._buildTokenUrl(url, vizOptions.token);
    }

    return url;
  }

  public static _buildTokenUrl(url: URL, token: string): URL {
    const tokenUrl = new URL(url.origin + this.authenticationPath);
    tokenUrl.searchParams.append(this.tokenParamName, token);
    tokenUrl.searchParams.append(this.viewParamName, url.toString().substring(url.origin.length));

    return tokenUrl;
  }

  /**
   * Add default parameters to url
   */
  private static _createDefaultParameters(url: URL, embeddingId: number): Map<VizOptionNames, string> {
    const defaultParameters: Map<VizOptionNames, string> = new Map();
    defaultParameters[VizOptionNames.Embed] = VizUrl.yes;

    // This is used to tell the viz that it is embedded and who to talk to. Ideally
    // we will use a MessageChannel after the initial load so we don't need to dispatch
    defaultParameters[VizOptionNames.ApiID] = `embhost${embeddingId}`;

    // TFS 1287448: Fix this Public hack
    if (url.hostname === VizUrl.publicHostName) {
      defaultParameters[VizOptionNames.ShowVizHome] = VizUrl.no;
    }

    defaultParameters[VizOptionNames.EmbedV3] = VizUrl.yes;
    const internalVersionStr =
      INTERNAL_CONTRACT_VERSION.major + '.' + INTERNAL_CONTRACT_VERSION.minor + '.' + INTERNAL_CONTRACT_VERSION.fix;
    defaultParameters[VizOptionNames.ApiInternalVersion] = internalVersionStr;

    const externalVersionStr = ApiVersion.Instance && ApiVersion.Instance.formattedValue; // maj.min.fix (no build)
    defaultParameters[VizOptionNames.ApiExternalVersion] = externalVersionStr;

    // TODO: investigate nav values and make an enum showing acceptable values
    // used to manage sessions server-side
    defaultParameters[VizOptionNames.NavType] = '0';
    defaultParameters[VizOptionNames.NavSrc] = 'Opt';
    return defaultParameters;
  }

  private static _appendFiltersToUrl(url: URL, filters: FilterParameters[]): void {
    for (const filter of filters) {
      url.searchParams.append(filter.field, filter.value);
    }
  }

  private static _appendDefaultParametersToUrl(url: URL, defaultParameters: Map<VizOptionNames, string>): URL {
    Object.keys(defaultParameters).forEach((key: string) => {
      // don't overwrite any values already written, and don't add empty default values
      if (!!defaultParameters[key] && !url.searchParams[key]) {
        url.searchParams.append(key, defaultParameters[key]);
      }
    });
    return url;
  }

  // exposed for testing
  public static _appendUserOptionsToUrl(url: URL, vizOptions: VizSettings): URL {
    // the viz may not have attributes with the values not set, and could include junk attributes
    // q: are there any default values? assuming not
    const vizAttributeNames = Object.keys(vizOptions);
    vizAttributeNames.forEach((key: string) => {
      const parameterValue = vizOptions[key];
      if (parameterValue === null || parameterValue === undefined) {
        return; // ignore null/unset values
      }
      const parameterName = VizOptionNames[key]; // check the list of vizql accepted params
      if (parameterName === null || parameterName === undefined) {
        return; // this attribute wasn't able to map to a known vizql param
      }

      const cleanedValue = this.cleanValue(parameterName, parameterValue);
      url.searchParams.append(parameterName, cleanedValue);
    });

    return url;
  }

  public static _appendCustomParamsToUrl(url: URL, customParams: CustomParameter[]): void {
    customParams.forEach((param) => {
      url.searchParams.set(param.name, param.value);
    });
  }

  private static cleanValue(parameterName: string, v: unknown): string {
    // Some parameters need their values to be flipped (false in the property is a yes to server)
    switch (parameterName) {
      case VizOptionNames.hideTabs:
        return this.paramValueToYesNo(!v); // ! here to reverse the value hideTabs = true -> tabs:n
      case VizOptionNames.toolbar:
        if (v === Toolbar.Hidden) {
          return VizUrl.no;
        }

        return String(v);
      default:
        return this.paramValueToYesNo(v);
    }
  }

  private static paramValueToYesNo(v: unknown): string {
    const vstr = String(v);
    if (vstr === 'true') {
      return VizUrl.yes;
    } else if (vstr === 'false') {
      return VizUrl.no;
    } else {
      return vstr;
    }
  }
}
