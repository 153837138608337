import * as Contract from '@tableau/api-external-contract-js';
import { ConnectionDescriptionSummary } from '@tableau/api-internal-contract-js';

/**
 * Implementation of a connection summary.
 * This does not follow the Impl pattern as it is just a property bag.
 */
export class ConnectionSummary implements Contract.ConnectionSummary {
  public constructor(private _connectionInfo: ConnectionDescriptionSummary) { }

  public get name(): string {
    return this._connectionInfo.name;
  }

  public get id(): string {
    return this._connectionInfo.id;
  }

  public get serverURI(): string {
    return this._connectionInfo.serverURI;
  }

  public get type(): string {
    return this._connectionInfo.type;
  }
}
