// All enum values made available to Embedding developers.
// Enums should be kept in alphabetical order.
// Most of them should match an enum on the server defined at
// workgroup/vqlweb/scriptsharp/src/ApiShared/PublicEnums.cs#L398:17

/**
 * device types available to use with @VizInterfaces:VizCreateOptions:device
 */
export enum DeviceType {
  Default = 'default',
  Desktop = 'desktop',
  Tablet = 'tablet',
  Phone = 'phone'
}

/**
 * All error codes used by the Embedding API.
 */
export enum EmbeddingErrorCodes {
  /**
   * The browser is not capable of supporting the Tableau JavaScript API
   */
  BrowserNotCapable = 'browser-not-capable',
  /**
   * The permissions on a workbook or a view do not allow downloading the workbook.
   */
  DownloadWorkbookNotAllowed = 'download-workbook-not-allowed',
  /**
   * Thrown when an error occurrs while attempting to perform a filter operation.
   */
  FilterCannotBePerformed = 'filter-cannot-be-performed',
  /**
   * Thrown when attempting to switch to a sheet by index that does not exist in the workbook.
   */
  IndexOutOfRange = 'index-out-of-range',
  /**
   * Thrown when an error occurrs within the Tableau JavaScript API. Contact Tableau Support.
   */
  InternalError = 'internal-error',
  /**
   * An invalid aggregation was specified for the filter, such as setting a range filter to "SUM(Sales)" instead of
   * "Sales".
   */
  InvalidAggregationFieldName = 'invalid-aggregation-field-name',
  /**
   * An operation was attempted on a custom view that does not exist.
   */
  InvalidCustomViewName = 'invalid-custom-view-name',
  /**
   * An invalid date was specified in a method that required a date parameter.
   */
  InvalidDateParameter = 'invalid-date-parameter',
  /**
   * A filter operation was attempted on a field that does not exist in the data source.
   */
  InvalidFilterFieldName = 'invalid-filter-name',
  /**
   * Either a filter operation was attempted on a field that does not exist in the data source, or the value supplied
   * in the filter operation is the wrong data type or format.
   */
  invalidFilterFieldNameOrValue = 'invalid-filter-field-name',
  /**
   * A filter operation was attempted using a value that is the wrong data type or format.
   */
  InvalidFilterFieldValue = 'invalid-filter-fieldValue',
  /**
   * A parameter is not the correct data type or format. The name of the parameter is specified in the Error.message
   * field.
   */
  InvalidParameter = 'invalid-parameter',
  /**
   * An invalid date value was specified in a Sheet.selectMarksAsync() call for a date field.
   */
  InvalidSelectionDate = 'invalid-selection-date',
  /**
   * A field was specified in a Sheet.selectMarksAsync() call that does not exist in the data source.
   */
  InvalidSelectionFieldName = 'invalid-selection-fieldName',
  /**
   * An invalid value was specified in a Sheet.selectMarksAsync() call.
   */
  InvalidSelectionValue = 'invalid-selection-value',
  /**
   * A negative size was specified or the maxSize value is less than minSize in Sheet.changeSizeAsync().
   */
  InvalidSize = 'invalid-size',
  /**
   * A behavior other than SheetSizeBehavior.AUTOMATIC was specified in Sheet.changeSizeAsync() when the sheet is a
   * Worksheet instance.
   */
  InvalidSizeBehaviorOnWorksheet = 'invalid-size-behavior-on-worksheet',
  /**
   * The URL specified in the Viz class constructor is not valid.
   */
  InvalidUrl = 'invalid-url',
  /**
   * The maxSize field is missing in Sheet.changeSizeAsync() when specifying SheetSizeBehavior.ATMOST.
   */
  MissingMaxSize = 'missing-max-size',
  /**
   * The minSize field is missing in Sheet.changeSizeAsync() when specifying SheetSizeBehavior.ATLEAST.
   */
  MissingMinMaxSize = 'missing-min-max-size',
  /**
   * The rangeN field is missing for a relative date filter of type LASTN or NEXTN.
   */
  MissingRangeNForRelativeDateFilters = 'missing-range-n-for-relative-date-filters',
  /**
   * An attempt was made to access Sheet.getUrl() on a hidden sheet. Hidden sheets do not have URLs.
   */
  NoUrlForHiddenWorksheet = 'no-url-for-hidden-worksheet',
  /**
   * One or both of the parentElement or the URL parameters is not specified in the Viz constructor.
   */
  NoUrlOrParentElementNotFound = 'no-url-or-parent-element-not-found',
  /**
   * An operation was attempted on a sheet that is not active or embedded within the active dashboard.
   */
  NotActiveSheet = 'not-active-sheet',
  /**
   * A required parameter was not specified, null, or an empty string/array.
   */
  NullOrEmptyParameter = 'null-or-empty-parameter',
  /**
   * A general-purpose server error occurred. Details are contained in the Error object.
   */
  ServerError = 'server-error',
  /**
   * An operation was attempted on a sheet that does not exist in the workbook.
   */
  SheetNotInWorkbook = 'sheet-not-in-workbook',
  /**
   * An operation is performed on a CustomView object that is no longer valid (it has been removed).
   */
  StaleDataReference = 'stale-data-reference',
  /**
   * An unknown event name was specified in the call to Viz.addEventListener or Viz.removeEventListener.
   */
  UnsupportedEventName = 'unsupported-event-name',
  /**
   * A Viz object has already been created as a child of the parentElement specified in the Viz constructor.
   */
  VizAlreadyInManager = 'viz-already-in-manager',
  /**
   * Function is not supported in the embedding api.
   */
  ImplementationError = 'wrong-implementation',
  /**
   * @NotImplemented
   */
  NotImplemented = 'not-implemented',
  /**
   * Unknown error during event initialization
   */
  EventInitializationError = 'event-initialization-error',
}


/**
 * formats available to use with displayStaticImage in @VizInterfaces
 */
export enum FileFormats {
  PDF = 'pdf',
  PNG = 'png'
}

export enum RevertVizSelection {
  All = 'all',
  Axes = 'axes',
  Filters = 'filters',
  Sorts = 'sorts',
  Shelves = 'shelves'
}

export enum ToolbarButtons {
  Alerts = 'alerts',
  AskData = 'askData',
  CustomViews = 'customViews',
  DataDetails = 'dataDetails',
  Share = 'share',
  Subscribe = 'subscribe'
}

export enum Toolbar {
  Top = 'top',
  Bottom = 'bottom',
  Hidden = 'hidden'
}

export enum SheetSizeBehavior {
  Automatic = 'automatic',
  Exactly = 'exactly',
  Range = 'range',
  AtLeast = 'atleast',
  AtMost = 'atmost'
}

/**
 * Be careful when adding new event types. Extensions and embedding follow two different patterns on how events are named.
 * Events should be all lower case and without hypens or underscores.
 */

/**
 * Represents the type of tableau embedding event that can be listened for.
 */
export enum TableauEventType {
  /** Raised when any filter has changed state. You can use this event type with TableauViz objects.*/
  FilterChanged = 'filterchanged',

  /** The selected marks on a visualization has changed. You can use this event type with TableauViz objects.*/
  MarkSelectionChanged = 'markselectionchanged',

  /** Fired when viz size is known */
  FirstVizSizeKnown = 'firstvizsizeknown',

  /** Fired when a viz first becomes interactive */
  FirstInteractive = 'firstinteractive',
}

