import * as Contract from '@tableau/api-external-contract-js';
import { FilterUpdateType, Mark } from '@tableau/api-external-contract-js';
import { TableauError, WorksheetImpl } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { Dashboard } from './Dashboard';
import { Sheet } from './Sheet';

export class Worksheet extends Sheet implements Contract.EmbeddingWorksheet {
  public constructor(private _worksheetImpl: WorksheetImpl, _workbookImpl: EmbeddingWorkbookImpl) {
    super(_worksheetImpl, _workbookImpl);
  }

  public get parentDashboard(): Contract.EmbeddingDashboard | null {
    if (this._worksheetImpl.hasParentDashboard()) {
      return new Dashboard(this._worksheetImpl.parentDashboard, this._workbookImpl);
    }
    // Worksheet doesn't belong to a dashboard. Return null.
    return null;
  }

  public get parentStoryPoint(): Contract.EmbeddingStoryPoint {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public getDataSourcesAsync(): Promise<Array<Contract.DataSource>> {
    return this._worksheetImpl.getDataSourcesAsync();
  }

  public getSummaryDataAsync(options: Contract.GetSummaryDataOptions): Promise<Contract.DataTable> {
    return this._worksheetImpl.getSummaryDataAsync(options);
  }

  public getUnderlyingDataAsync(options: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    console.warn(
      'Worksheet.getUnderlyingDataAsync is deprecated. Please use ' +
        'Worksheet.getUnderlyingTablesAsync and Worksheet.getUnderlyingTableDataAsync',
    );
    return this._worksheetImpl.getUnderlyingDataAsync(options);
  }

  public getUnderlyingTablesAsync(): Promise<Array<Contract.LogicalTable>> {
    return this._worksheetImpl.getUnderlyingTablesAsync();
  }

  public getUnderlyingTableDataAsync(logicalTableId: string, options: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    return this._worksheetImpl.getUnderlyingTableDataAsync(logicalTableId, options);
  }

  public getFiltersAsync(): Promise<Array<Contract.Filter>> {
    return this._worksheetImpl.getFiltersAsync();
  }

  public applyFilterAsync(
    fieldName: string,
    values: Array<string>,
    updateType: FilterUpdateType,
    options: Contract.FilterOptions,
  ): Promise<string> {
    return this._worksheetImpl.applyFilterAsync(fieldName, values, updateType, options);
  }

  public applyRangeFilterAsync(fieldName: string, filterOptions: Contract.RangeFilterOptions): Promise<string> {
    return this._worksheetImpl.applyRangeFilterAsync(fieldName, filterOptions);
  }

  public clearFilterAsync(fieldName: string): Promise<string> {
    return this._worksheetImpl.clearFilterAsync(fieldName);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public applyRelativeDateFilterAsync(fieldName: string, options: Contract.RelativeDateFilterOptions): Promise<string> {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Method not implemented.');
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public applyHierarchicalFilterAsync(fieldName: string, values: object, options: Contract.HierarchicalFilterOptions): Promise<string> {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Method not implemented.');
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  public clearSelectedMarksAsync(): Promise<void> {
    return this._worksheetImpl.clearSelectedMarksAsync();
  }

  public getSelectedMarksAsync(): Promise<Contract.MarksCollection> {
    return this._worksheetImpl.getSelectedMarksAsync();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public selectMarksAsync(fieldName: string, value: object | object[], updateType: Contract.SelectionUpdateType): void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  public selectMarksAsync(fieldValuesMap: object, updateType: Contract.SelectionUpdateType): void;
  public selectMarksAsync(marks: Mark[], updateType: Contract.SelectionUpdateType): void;

  /* eslint-disable @typescript-eslint/no-unused-vars */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public selectMarksAsync(fieldName: any, value: any, updateType?: any): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Method not implemented.');
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
  /* eslint-enable @typescript-eslint/no-explicit-any */
}
