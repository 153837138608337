import * as Contract from '@tableau/api-external-contract-js';
import { DashboardImpl, SheetInfoImpl, TableauError, WorksheetImpl } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { Dashboard } from './Dashboard';
import { Sheet } from './Sheet';
import { SheetInfo } from './SheetInfo';
import { Worksheet } from './Worksheet';

export class Workbook implements Contract.EmbeddingWorkbook {
  public constructor(private _workbookImpl: EmbeddingWorkbookImpl) {}

  public get activeSheet(): Contract.EmbeddingSheet {
    switch (this._workbookImpl.activeSheet.sheetType) {
      case Contract.SheetType.Worksheet: {
        return new Worksheet(this._workbookImpl.activeSheet as WorksheetImpl, this._workbookImpl);
      }
      case Contract.SheetType.Dashboard: {
        return new Dashboard(this._workbookImpl.activeSheet as DashboardImpl, this._workbookImpl);
      }
      case Contract.SheetType.Story: {
        // todo : 1278976. Once story is implemented, return story.
        return new Sheet(this._workbookImpl.activeSheet as DashboardImpl, this._workbookImpl);
      }
      default: {
        throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, 'Unable to initialize ActiveSheet');
      }
    }
  }

  public get publishedSheetsInfo(): Array<Contract.SheetInfo> {
    return this._workbookImpl.publishedSheetsInfo.map((sheetInfoImpl: SheetInfoImpl) => {
      return new SheetInfo(sheetInfoImpl, this._workbookImpl);
    });
  }

  public get name(): string {
    return this._workbookImpl.name;
  }

  public get activeCustomView(): string {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public activateSheetAsync(sheetNameOrIndex: object): Promise<Sheet> {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  public revertAllAsync(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public getCustomViewsAsync(): Promise<Contract.CustomView[]> {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public showCustomViewAsync(customViewName: string): Promise<Contract.CustomView> {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public removeCustomViewAsync(customViewName: string): Promise<Contract.CustomView> {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public rememberCustomViewAsync(customViewName: string): Promise<Contract.CustomView> {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public setActiveCustomViewAsDefaultAsync(): void {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }
}
