import {
  DeviceType, FileFormats, SheetSizeBehavior, ToolbarButtons, Toolbar,
  RevertVizSelection,
  TableauEventType
} from './Enums';
import { Size } from '../Shared/SheetInterfaces';
import { Worksheet, SheetInfo } from './SheetInterfaces';
import { Workbook } from './WorkbookInterface';

/**
 * All the string constants used as attribute names in html
 */
export enum VizAttributes {
  Src = 'src',
  Width = 'width',
  Height = 'height',
  DisableUrlActionsPopups = 'disable-url-actions',
  HideTabs = 'hide-tabs',
  Toolbar = 'toolbar',
  InstanceIdToClone = 'instance-id-to-clone',
  Device = 'device',
  Token = 'token',
  TouchOptimize = 'touch-optimize',
  Debug = 'debug'
}
/**
 * String constants used as child tags in html
 */
export enum VizChildElements {
  VizFilter = 'viz-filter',
  CustomParameter = 'custom-parameter'
}

export enum VizChildElementAttributes {
  Field = 'field',
  Value = 'value',
  Name = 'name'
}

/**
 * This defines the interface that users see for the top level viz object
 * There should be an attribute in here for each value in VizAttributeNames
 **/
export interface Viz extends VizSettings, VizLocalSettings, VizActions {
  /**
   * One Workbook is supported per visualization.
   */
  readonly workbook?: Workbook;
}

/**
 * Not yet implemented - events and state of the viz
 */
export interface VizActions {

  /**
   * @NotImplemented
   * Indicates whether automatic updates are currently paused.
   */
  readonly automaticUpdatesArePaused?: boolean;

  /**
   * @NotImplemented
   * Pause layout updates. This is useful if you are resizing the visualization or performing multiple calls that could affect the layout.
   */
  pauseAutomaticUpdatesAsync?(): void;

  /**
   * @NotImplemented
   * Resume layout updates.
   */
  resumeAutomaticUpdatesAsync?(): void;

  /**
   * @NotImplemented
   * Toggle layout updates.
   */
  toggleAutomaticUpdatesAsync?(): void;

  /**
   * @NotImplemented
   * Equivalent to clicking on the Revert All toolbar button, which restores the workbook to its starting state.
   */
  revertAllAsync?(): void;

  /**
   * @NotImplemented
   * Equivalent to clicking on the Refresh Data toolbar button.
   */
  refreshDataAsync?(): void;

  /**
   * @NotImplemented
   * Equivalent to clicking on the Download toolbar button, which downloads a copy of the original workbook.
   */
  showDownloadWorkbookDialog?(): void;

  /**
   * @NotImplemented
   * Equivalent to clicking on the Export Image toolbar button, which creates a PNG file of the current visualization.
   */
  showExportImageDialog?(): void;

  /**
   * @NotImplemented
   * Equivalent to clicking on the Export PDF toolbar button, which shows a dialog allowing the user to select options for the export.
   */
  showExportPDFDialog?(): void;

  /**
   * @NotImplemented
   * Shows the Export Data dialog, which is currently a popup window. The worksheetInDashboard parameter is optional. If not specified,
   * the currently active Worksheet is used.
   */
  showExportDataDialog?(worksheetInDashboard: Worksheet | SheetInfo | string): void;

  /**
   * @NotImplemented
   * Shows the Export CrossTab dialog. The worksheetInDashboard parameter is optional. If not specified, the currently active Worksheet is
   * used.
   */
  showExportCrossTabDialog?(worksheetInDashboard: Worksheet | SheetInfo | string): void;

  /**
   * @NotImplemented
   * Equivalent to clicking on the Share toolbar button, which displays a dialog allowing the user to share the visualization by email or
   * by embedding its HTML in a web page.
   */
  showShareDialog?(): void;

  /**
   * @NotImplemented
   * Gets the Src of the visualization asynchronously.
   */
  getCurrentSrcAsync?(): Promise<string>;

  /**
   * @NotImplemented
   * Redoes last action on a sheet, defaults to a single redo unless optional parameters is specified.
   */
  redoAsync?(): void;

  /**
   * @NotImplemented
   * Undoes action on sheet, defaults to a single undo unless optional parameters is specified.
   */
  undoAsync?(): void;

  /**
   * Adds an event listener to the specified event.
   *
   * ```
   * async function getSelectedMarks(event) {
   *     const marksSelected = await event.detail.getMarksAsync();
   *     const numMarks = marksSelected.data[0].data.length;
   *     console.log(`${numMarks} marks Selected`);
   * }
   *
   * let viz = document.getElementById('tableauViz');
   * viz.addEventListener("markselectionchanged", getSelectedMarks);
   * ```
   */
  addEventListener(type: TableauEventType, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;

  /**
   * Removes an event listener from the specified event.
   *
   * ```
   * let viz = document.getElementById('tableauViz');
   * viz.removeEventListener("markselectionchanged", getSelectedMarks);
   * ```
   */
  removeEventListener(type: TableauEventType, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
}

export interface VizSize {

  /**
   * Gets the sheetSize record for the current sheet
   */
  readonly sheetSize: SheetSize;

  /**
   * Gets the height of the Tableau UI elements (the chrome) surrounding the view.
   */
  readonly chromeHeight: number;
}

export interface SheetSize {
  /**
   * Contains an enumeration value of one of the following: AUTOMATIC, EXACTLY, RANGE, ATLEAST, and ATMOST.
   */
  readonly behavior: SheetSizeBehavior;

  /**
   *  This is only defined when behavior is EXACTLY, RANGE, or ATLEAST.
   */
  readonly minSize: Size;

  /**
   *  This is only defined when behavior is EXACTLY, RANGE or ATMOST.
   */
  readonly maxSize: Size;
}

/**
 * This interface represents all the options that can be passed through to vizql
 * Names of attributes on this interface must match names on VqlInterface.VizOptionNames
 * For Dev Preview, it is limited to options that are available in v2 on VizCreateOptions
 */
export interface VizSettings {


  /**
   * Indicates whether to suppress the execution of URL actions. This option does not prevent the URL action
   * event from being raised. You can use this option to change what happens when a URL action occurs. If set
   * to true and you create an event listener for the URL_ACTION event, you can use an event listener
   * handler to customize the actions.
   *
   * ```
   * <tableau-viz id="tableauViz"  disable-url-actions />
   * ```
   */
  disableUrlActionsPopups?: boolean;

  /**
   * Indicates whether tabs are hidden or shown.
   *
   * ```
   * <tableau-viz id="tableauViz"  hide-tabs />
   * ```
   */
  hideTabs?: boolean;

  /**
   * Specifies the position of the toolbar, if it is shown. The values can be Toolbar.Top,
   * Toolbar.Bottom or Toolbar.Hidden.
   * If not specified, defaults to Toolbar.Bottom.
   *
   * ```
   * <tableau-viz id="tableauViz"  toolbar="hidden" />
   * ```
   */
  toolbar?: Toolbar;

  /**
   * Specifies a device layout for a dashboard, if it exists.
   * Values can be default, desktop, tablet, or phone.
   * If not specified, defaults to loading a layout based on the
   * smallest dimension of the hosting iframe element.
   *
   * ```
   * <tableau-viz id="tableauViz"  device="desktop" />
   * ```
   */
  device?: DeviceType;

  /**
   * Specifies the ID of an existing instance to make a copy (clone) of.
   * This is useful if the user wants to continue analysis of an existing visualization
   * without losing the state of the original. If the ID does not refer to an existing visualization,
   * the cloned version is derived from the original visualization.
   *
   * ```
   * <tableau-viz id="tableauViz"  instance-id-to-clone="id1" />
   * ```
   */
  instanceIdToClone?: string;

  /**
  * The token used for authorization
  *
  * ```
  * <tableau-viz id="tableauViz" token="some-token-containing-clientId" />
  * ```
  */
  token?: string;

  /**
   * Indicates whether to touch optimize viz controls.
   *
   * ```
   * <tableau-viz id="tableauViz"  touch-optimize />
   * ```
   */
  touchOptimize?: boolean;

  /**
   * Enabled debugging for the component, and Tableau Server if it is enabled.
   *
   * ```
   * <tableau-viz id="tableauViz"  debug />
   * ```
   */
  debug?: boolean;
}

/**
 * User supplied options that do not get passed through to vizql
 * For Developer Preview this will implement all the options that are available in
 * in v2 on VizCreateOptions
 */
export interface VizLocalSettings {


  /**
   * The viz src
   */
  src?: string | null;

  /**
   * Represents width in pixels
   * Can be any valid CSS size specifier. If not specified, defaults to the published width of the view.
   */
  width?: string | number;

  /**
   * Represents height in pixels
   * Can be any valid CSS size specifier. If not specified, defaults to the published height of the view.
   */
  height?: string | number;

}

export interface FilterParameters {
  field: string;
  value: string;
}

/**
 * CustomParameters are used to pass along settings, information, etc., that aren't exposed on the
 * tableau-viz element. CustomParameters will override attributes, or filters, set on the tableau-viz element.
 *
 * * ```
   * <tableau-viz id="tableauViz">
   *  <custom-parameter name="parameter-name" value="parameter-value" />
   * </tableau-viz>
   * ```
 *
 */
export interface CustomParameter {
  /**
   * Name of the parameter to be set.
   */
  name: string;

  /**
   * Valid value for the parameter being set.
   */
  value: string;
}

/**
 * Options present in v2 that are not yet implemented
 * We may not want to implement them at all
 */
export interface V2Options {

  navType?: string; // used to manage sessions server-side TODO make an enum showing acceptable values
  displayStaticImage?: boolean; // if this is set, most options are ignored
  displayStaticFormat?: FileFormats; // if displayStaticImage, which format to display
  staticImageUrl?: string;

  forceOriginalView?: boolean; // why would you need this?
  iframeSizedToWindow?: boolean; // Indicates whether iframe is set to the size of window or not.
  loadOrder?: number; // do we still need this?
  linkTarget?: string;

  openid_hd?: boolean;
  openid_login_hint?: string;

  recordPerformance?: boolean;
  fetchFreshData?: boolean; // == refresh=yes

  renderInBrowser?: number; // 0 = no, 100 = always
  revertWork?: RevertVizSelection;


  hideMobileAppBanner?: boolean;
  hideToolbarButtons?: ToolbarButtons[];

  hideTooltips?: boolean;
  optimizeForTouch?: boolean;
  useLowDpi?: boolean;

}

/**
 * more v2 options - specific to Tableau Public
 */
export interface TableauPublicOptions {
  showVizHome?: boolean;
  showDisplayCount?: boolean;
}

/**
 * more v2 options - Trusted Tickets implementation
 */
export interface TrustedTicketOptions {
  path?: string;  // do we need to keep this option? or maybe we keep this and not ticket?
  siteRoot: string; // includes the #??? example value='/#/Sales'
  ticket?: string; // example value='9D1ObyqDQmSIOyQpKdy4Sw==:dg62gCsSE0QRArXNTOp6mlJ5'
  name?: string; // complete viz name: value='ExampleCoSales/Sales/jsmith@example.com/EastRegionSales'
}
