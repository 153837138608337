import * as Contract from '@tableau/api-external-contract-js';
import { EmbeddingErrorCodes } from '@tableau/api-external-contract-js';
import {
  CrossFrameMessenger,
  EmbeddingBootstrapInfo,
  FirstVizSizeKnownEvent as FirstVizSizeKnownModel,
  NotificationId,
} from '@tableau/api-internal-contract-js';
import {
  ApiServiceRegistry,
  CrossFrameDispatcher,
  NotificationService,
  registerAllSharedServices,
  ServiceNames,
  TableauError,
} from '@tableau/api-shared-js';
import { TableauViz } from '../Components/TableauViz';
import { FirstVizSizeKnownEvent } from '../Events/FirstVizSizeKnownEvent';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { SheetSizeFactory } from '../Models/SheetSize';
import { VizSize } from '../Models/VizSize';
import { VizUrl } from '../Models/VizUrl';
import { registerAllEmbeddingServices } from '../Services/RegisterAllEmbeddingServices';
import { VizManager } from '../VizManager';

export class VizImpl {
  private _workbookImpl: EmbeddingWorkbookImpl;
  private _embeddingId: number;
  private _frameUrl: URL;
  private _vizSize: VizSize;

  public constructor(
    private _viz: TableauViz,
    private _iframe: HTMLIFrameElement,
    private _src: string,
    private _options: Contract.VizSettings,
    private _filters: Contract.FilterParameters[],
    private _customParams: Contract.CustomParameter[],
  ) {
    if (!this._src) {
      throw new TableauError(
        Contract.EmbeddingErrorCodes.InternalError,
        'We should not have attempted to render the component without a src',
      );
    }
    if (!this._iframe) {
      throw new TableauError(Contract.EmbeddingErrorCodes.InternalError, 'Iframe has not been created yet');
    }

    this._embeddingId = VizManager.registerViz(this._viz);
    this._frameUrl = VizUrl.buildUrl(this._src, this._options, this._embeddingId, this._filters, this._customParams);
  }

  public get workbookImpl(): EmbeddingWorkbookImpl {
    return this._workbookImpl;
  }

  public get iframe(): HTMLIFrameElement {
    return this._iframe;
  }

  public get embeddingId(): number {
    return this._embeddingId;
  }

  public initializeViz(): void {
    try {
      const iframeWindow = this._iframe.contentWindow;
      if (!iframeWindow) {
        throw new TableauError(EmbeddingErrorCodes.InternalError, 'Iframe has not been created yet');
      }

      const messenger = new CrossFrameMessenger(window, iframeWindow, this._frameUrl.origin);
      const dispatcher = new CrossFrameDispatcher(messenger);
      registerAllSharedServices(dispatcher, this.embeddingId);
      registerAllEmbeddingServices(dispatcher, this.embeddingId);
      const notificationService = ApiServiceRegistry.get(this.embeddingId).getService<NotificationService>(ServiceNames.Notification);
      const vizSizeKnownUnregister = notificationService.registerHandler(
        NotificationId.FirstVizSizeKnown,
        () => true,
        (model: FirstVizSizeKnownModel) => {
          this.handleVizSizeKnownEvent(model);
          vizSizeKnownUnregister();
        },
      );
      const vizInteractiveUnregister = notificationService.registerHandler(
        NotificationId.VizInteractive,
        () => true,
        (model: EmbeddingBootstrapInfo) => {
          this.handleVizInteractiveEvent(model);
          vizInteractiveUnregister();
        },
      );
      messenger.startListening();
      this._iframe.src = this._frameUrl.toString();
    } catch (e) {
      throw new TableauError(Contract.EmbeddingErrorCodes.InternalError, 'Unexpected error during initialization.');
    }
  }

  public dispose(): void {
    VizManager.unregisterViz(this._embeddingId);
  }

  private handleVizInteractiveEvent(bootstrapInfo: EmbeddingBootstrapInfo): void {
    this._workbookImpl = new EmbeddingWorkbookImpl(bootstrapInfo, this.embeddingId);
    this._viz.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.FirstInteractive));
  }

  private handleVizSizeKnownEvent(model: FirstVizSizeKnownModel): void {
    const sheetSize = SheetSizeFactory.FromSizeConstraints(model.sheetSize);
    this._vizSize = new VizSize(sheetSize, model.chromeHeight);
    const vizSizeEvent = new FirstVizSizeKnownEvent(this._vizSize);
    this._viz.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.FirstVizSizeKnown, { detail: vizSizeEvent }));
  }
}
