import * as Contract from '@tableau/api-external-contract-js';
import { TableInfo } from '@tableau/api-internal-contract-js';

/**
 * Implementation of a table summary.
 * This does not follow the Impl pattern as it is just a property bag.
 */
export class TableSummary implements Contract.TableSummary {
  public constructor(private _tableInfo: TableInfo) { }

  public get name(): string {
    return this._tableInfo.name;
  }

  public get id(): string {
    return this._tableInfo.id;
  }

  public get connectionId(): string {
    return this._tableInfo.connectionId;
  }

  public get customSQL(): string | undefined {
    return this._tableInfo.customSQL;
  }
}
