import { ErrorCodes } from '@tableau/api-external-contract-js';

import { TableauError } from '../TableauError';

/**
 * This class converts from a source enum value to destination enum
 * value given a mapping from source to destination when constructed.
 *
 * Note: This exact same class is defined in api-core.  Given its small
 * nature, it is not worth having in a separate project to share this between
 * api-core and api-shared.  If more utility functionality is added that is used by api-core
 * and api-shared but has no other dependecies, a utiltity project might be merited,
 * and this class could be moved.
 */
export class EnumConverter<TSourceType extends string, TDestinationType> {
  public constructor(
    private _mappings: { [enumVal: string]: TDestinationType; },
    private _defaultVal?: TDestinationType) { }

  public convert(enumVal: TSourceType, throwIfMissing: ShouldThrow = ShouldThrow.Yes): TDestinationType {
    if (this._mappings.hasOwnProperty(enumVal)) {
      return this._mappings[enumVal as string];
    }

    if (this._defaultVal !== undefined && throwIfMissing !== ShouldThrow.Yes) {
      return this._defaultVal;
    }

    throw new TableauError(ErrorCodes.InternalError, `Enum Mapping not found for: ${enumVal}`);
  }
}

export enum ShouldThrow {
  Yes = 'yes',
  No = 'no',
}
