import * as Contract from '@tableau/api-external-contract-js';
import { SheetSizeOptions, SheetType } from '@tableau/api-external-contract-js/';
import { EventListenerManager, SheetImpl, TableauError } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { SheetSize } from './SheetSize';
import { Workbook } from './Workbook';

export class Sheet extends EventListenerManager implements Contract.EmbeddingSheet {
  public constructor(private _sheetImpl: SheetImpl, protected _workbookImpl: EmbeddingWorkbookImpl) {
    super();
  }

  public get isHidden(): boolean {
    return this._sheetImpl.hidden;
  }

  public get isActive(): boolean {
    return this._sheetImpl.active;
  }

  public get name(): string {
    return this._sheetImpl.name;
  }

  public get sheetType(): SheetType {
    return this._sheetImpl.sheetType;
  }

  public get size(): Contract.SheetSize {
    if (this._sheetImpl.size instanceof SheetSize) {
      return this._sheetImpl.size;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public get url(): string {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public changeSizeAsync(sheetSizeOptions: SheetSizeOptions): Promise<Contract.SheetSize> {
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public get index(): number {
    return this._sheetImpl.index;
  }

  public get workbook(): Contract.EmbeddingWorkbook {
    return new Workbook(this._workbookImpl);
  }
}
