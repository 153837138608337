import * as Contract from '@tableau/api-external-contract-js';
import { SizeConstraint } from '@tableau/api-internal-contract-js';
import { Size as SizeBase } from '@tableau/api-shared-js';

export class SheetSize implements Contract.SheetSize {
  public constructor(private _behavior: Contract.SheetSizeBehavior, private _minSize: Contract.Size, private _maxSize: Contract.Size) {}

  public get behavior(): Contract.SheetSizeBehavior {
    return this._behavior;
  }

  public get minSize(): Contract.Size {
    return this._minSize;
  }

  public get maxSize(): Contract.Size {
    return this._maxSize;
  }
}

export class SheetSizeFactory {
  // This is a direct port from SheetSize.cs - SheetSizeFactory
  public static FromSizeConstraints(sizeConstraint: SizeConstraint): SheetSize {
    const minHeight = sizeConstraint.minHeight;
    const minWidth = sizeConstraint.minWidth;
    const maxHeight = sizeConstraint.maxHeight;
    let maxWidth = sizeConstraint.maxWidth;

    let behavior = Contract.SheetSizeBehavior.Automatic;

    let minSize = new SizeBase(0, 0);
    let maxSize = new SizeBase(0, 0);

    if (minHeight === 0 && minWidth === 0) {
      if (maxHeight === 0 && maxWidth === 0) {
        // AUTOMATIC
      } else {
        behavior = Contract.SheetSizeBehavior.AtMost;
        maxSize = new SizeBase(maxHeight, maxWidth);
      }
    } else if (maxHeight === 0 && maxWidth === 0) {
      behavior = Contract.SheetSizeBehavior.AtLeast;
      minSize = new SizeBase(minHeight, minWidth);
    } else if (maxHeight === minHeight && maxWidth === minWidth && minWidth > 0) {
      behavior = Contract.SheetSizeBehavior.Exactly;
      minSize = new SizeBase(minHeight, minWidth);
      maxSize = new SizeBase(minHeight, minWidth);
    } else {
      behavior = Contract.SheetSizeBehavior.Range;

      // 'Fit Width' for a DSD layout
      if (minWidth === 0 && maxWidth === 0) {
        maxWidth = 2147483647; // max integer value
      }

      minSize = new SizeBase(minHeight, minWidth);
      maxSize = new SizeBase(maxHeight, maxWidth);
    }

    return new SheetSize(behavior, minSize, maxSize);
  }
}
