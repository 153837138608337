import * as Contract from '@tableau/api-internal-contract-js';
import {
  CommandResponseMessage,
  CrossFrameMessenger,
  MESSAGING_VERSION as ApiMessagingVersion,
  InitializationOptions,
} from '@tableau/api-internal-contract-js';

import { CrossFrameDispatcher } from './CrossFrameDispatcher';

// Checks to see if we are running in an iframe currently: https://stackoverflow.com/a/326076/8821153
function inIframe(thisWindow: Window): boolean {
  try {
    return thisWindow.self !== thisWindow.parent;
  } catch (e) {
    return true;
  }
}

/**
 * Attempts to bootstrap the extension with a cross-frame parent where Tableau is running
 *
 * @param thisWindow The window which we are running in (injected for unit testing purposes)
 * @param internalContractVersion The version number of the internal contract we are using
 * @returns A promise which is doing the actual bootstrapping
 */
export function doCrossFrameBootstrap(
  thisWindow: Window, internalContractVersion: Contract.VersionNumber, options: InitializationOptions)
  : Promise<Contract.InternalApiDispatcherFactory> {

  return new Promise<Contract.InternalApiDispatcherFactory>((resolve, reject) => {

    let parent: Window;

    // Normally, we are running inside an iframe.  The exception to this is
    // when we are running as an extension inside a dialog as part of the UINamespace
    // functionality.  In that case, we want the opener of this window rather than the parent.
    if (!inIframe(thisWindow)) {
      parent = thisWindow.opener;
    } else {
      parent = thisWindow.parent;
    }

    if (!parent) {
      reject('This extension is not running inside an iframe, desktop, or popup window. Initialization failed.');
    }

    // Create the messenger which will do he communication between this window and our parent
    // Since we don't know where we are running yet, we have to make this initial origin '*'. Once
    // we have successfully initialized our extension, we will limit where we send messages
    const messenger = new CrossFrameMessenger(thisWindow, parent, '*');

    // Prepare to send an initialization message to the parent frame
    const initializationMessage =
      messenger.prepareInitializationMessage(internalContractVersion, ApiMessagingVersion, options);

    // When we receive a response back from the parent, we check to make sure the guids match and then we know
    // that the parent is aware of us and we can start communicating
    messenger.setCommandResponseMessageHandler(function (msg: CommandResponseMessage): void {

      // Verify we are getting a response from our initialize message
      if (msg.commandGuid === initializationMessage.messageGuid) {

        // The versioning of the dispatcher happens on the other side of our frame, and
        // in a wrapper on this side. This one doesn't have any version knowledge.
        const dispatcherFactory = () => new CrossFrameDispatcher(messenger);
        resolve(dispatcherFactory);
      }
    });

    // Now that our handlers are ready, start listening and send our initialization message
    messenger.startListening();
    initializationMessage.send();
  });
}
