import * as Contract from '@tableau/api-external-contract-js';

import { DataSourceImpl } from './Impl/DataSourceImpl';

export class DataSource implements Contract.DataSource {
  public constructor(private _dataSourceImpl: DataSourceImpl) { }

  public get name(): string {
    return this._dataSourceImpl.name;
  }

  public get id(): string {
    return this._dataSourceImpl.id;
  }

  public get fields(): Array<Contract.Field> {
    return this._dataSourceImpl.fields;
  }

  public get extractUpdateTime(): string | undefined {
    return this._dataSourceImpl.extractUpdateTime;
  }

  public get isExtract(): boolean {
    return this._dataSourceImpl.isExtract;
  }

  public get isPublished(): boolean | undefined {
    return this._dataSourceImpl.isPublished;
  }

  public publishedUrl(): string | undefined {
    return this._dataSourceImpl.publishedUrl();
  }

  public refreshAsync(): Promise<void> {
    return this._dataSourceImpl.refreshAsync();
  }

  public getActiveTablesAsync(): Promise<Array<Contract.TableSummary>> {
    console.warn('DataSource.getActiveTablesAsync is deprecated. Please use DataSource.getLogicalTablesAsync.');
    return this._dataSourceImpl.getActiveTablesAsync();
  }

  public getConnectionSummariesAsync(): Promise<Array<Contract.ConnectionSummary>> {
    return this._dataSourceImpl.getConnectionSummariesAsync();
  }

  public getUnderlyingDataAsync(options?: Contract.DataSourceUnderlyingDataOptions):
    Promise<Contract.DataTable> {
    console.warn('DataSource.getUnderlyingDataAsync is deprecated. Please use ' +
      'DataSource.getLogicalTablesAsync and DataSource.getLogicalTableDataAsync.');
    return this._dataSourceImpl.getUnderlyingDataAsync(options);
  }

  public getLogicalTablesAsync(): Promise<Array<Contract.LogicalTable>> {
    return this._dataSourceImpl.getLogicalTablesAsync();
  }

  public getLogicalTableDataAsync(logicalTableId: string,
    options?: Contract.DataSourceUnderlyingDataOptions):
    Promise<Contract.DataTable> {
    return this._dataSourceImpl.getLogicalTableDataAsync(logicalTableId, options);
  }
}
