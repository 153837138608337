import { AnimationServiceImpl } from './impl/AnimationServiceImpl';
import { ApiServiceRegistry } from './ServiceRegistry';
import { DataSourceServiceImpl } from './impl/DataSourceServiceImpl';
import { FilterServiceImpl } from './impl/FilterServiceImpl';
import { GetDataServiceImpl } from './impl/GetDataServiceImpl';
import { InternalApiDispatcher, VersionNumber } from '@tableau/api-internal-contract-js';
import { NotificationServiceImpl } from './impl/NotificationServiceImpl';
import { ParametersServiceImpl } from './impl/ParametersServiceImpl';
import { SelectionServiceImpl } from './impl/SelectionServiceImpl';
import { VizServiceImpl } from './impl/VizServiceImpl';
import { ZoneServiceImpl } from './impl/ZoneServiceImpl';

export function registerAllSharedServices(dispatcher: InternalApiDispatcher, registryId?: number, platformVersion?: VersionNumber): void {
  ApiServiceRegistry.get(registryId).registerService(new AnimationServiceImpl(dispatcher));
  ApiServiceRegistry.get(registryId).registerService(new DataSourceServiceImpl(dispatcher, platformVersion));
  ApiServiceRegistry.get(registryId).registerService(new GetDataServiceImpl(dispatcher));
  ApiServiceRegistry.get(registryId).registerService(new FilterServiceImpl(dispatcher));
  ApiServiceRegistry.get(registryId).registerService(new NotificationServiceImpl(dispatcher));
  ApiServiceRegistry.get(registryId).registerService(new ParametersServiceImpl(dispatcher));
  ApiServiceRegistry.get(registryId).registerService(new SelectionServiceImpl(dispatcher));
  ApiServiceRegistry.get(registryId).registerService(new ZoneServiceImpl(dispatcher));
  ApiServiceRegistry.get(registryId).registerService(new VizServiceImpl(dispatcher));
}
