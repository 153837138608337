import { ErrorCodes } from '@tableau/api-external-contract-js';
import { TableauError } from './TableauError';
import { VersionNumberContract } from './VersionNumberContract';


/**
 * Represents the current version of the extensions, embedding
 * and extensions-api-types library
 */
export class VersionNumber implements VersionNumberContract {

  public major: number;
  public minor: number;
  public fix: number;
  public build: number;
  public isAlpha: boolean;

  // private constructor so everyone uses the singleton instance
  // build numbers have this form: M.m.f-pre.N
  public constructor(versionString: string, isAlpha: boolean) {
    const partStr = versionString.split('-');
    this.build = this.getBuildNumber(partStr[1]);
    versionString = partStr[0];

    const parts = versionString.split('.').map(p => parseInt(p, 10));
    if (parts.length !== 3) {
      throw new TableauError(ErrorCodes.InternalError, `Invalid version number: ${versionString}`);
    }

    this.major = parts[0];
    this.minor = parts[1];
    this.fix = parts[2];
    this.isAlpha = isAlpha;
  }

  private getBuildNumber(preReleaseString: string): number {
    const noBuildNumber: number = -1;
    if (!preReleaseString) {
      return noBuildNumber;
    }

    // The preRelease string has this form: pre.N, but we don't depend on the actual string being 'pre'
    const partStr = preReleaseString.split('.');
    return partStr[1] ? parseInt(partStr[1], 10) : noBuildNumber;
  }

  public get formattedValue(): string {
    return `${this.major}.${this.minor}.${this.fix}`;
  }

  public get fullFormattedValue(): string {
    return `${this.major}.${this.minor}.${this.fix}-pre.${this.build}`;
  }
}
