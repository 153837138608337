import * as guid from 'guid';

import { CrossFramePreparedMessage } from './CrossFramePreparedMessage';
import {
  CommandMessage,
  CommandResponseMessage,
  InitializeMessage,
  Message,
  MessageType,
  NotificationMessage,
  HandshakeMessage
} from './interface/MessageTypes';
import { Messenger } from './interface/Messenger';
import { PreparedMessage } from './interface/PreparedMessage';
import {
  isCommandMessage,
  isCommandResponseMessage,
  isInitMessage,
  isMessage,
  isNotificationMessage,
  isHandshakeMessage
} from './MessageTypeChecks';
import { VersionNumber, VerbId, ExecuteParameters, INTERNAL_CONTRACT_VERSION, Model, NotificationId } from '../JsApiInternalContract';
import { InitializationOptions } from '../interface/InitializationOptions';
import {
  InitializeMessageHandler,
  CommandResponseMessageHandler,
  CommandMessageHandler,
  NotificationMessageHandler,
  HandshakeMessageHandler
} from './interface/MessageListener';

/**
 * The CrossFrameMessenger is the primary export from the api-messaging module. An instance of
 * this class can be instantiated on both sides of a frame boundary to facilitate communication
 * in both directions between the frames. This class implements both the dispatcher and the listener
 * portions, but doesn't require callers to care about both.
 */
export class CrossFrameMessenger implements Messenger {
  private unregisterFunction?: (() => void);
  private initializeMessageHandler?: InitializeMessageHandler;
  private commandResponseMessageHandler?: CommandResponseMessageHandler;
  private commandMessageHandler?: CommandMessageHandler;
  private notificationMessageHandler?: NotificationMessageHandler;
  private handshakeMessageHandler?: HandshakeMessageHandler;

  /**
   * Creates an instance of CrossFrameMessenger. If you would like to use the CrossFrameMessenger as a MessageListener,
   * be sure to call StartListening and register message handlers.
   * @param thisWindow The window object which the CrossFrameMessenger lives. An onMessage listener will be added here.
   * @param [otherWindow] Optional otherWindow which messages will be posted to.
   *                      If defined, incoming messages must originate from otherWindow to be passed on
   * @param [otherWindowOrigin] The target origin which otherWindow must have in order to receive dispatched messages.
   *                            This value will be sent as the targetOrigin of a postMessage
   *                            (https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage)
   */
  public constructor(private thisWindow: Window, private otherWindow?: Window, private otherWindowOrigin?: string) {
    // Make sure to call StartListening
  }

  ///// MessageListener Implementation

  public startListening(): void {
    // Check if we already are listening, if not, hook up a message listener
    if (!this.unregisterFunction) {
      const boundHandler = this.onMessageReceived.bind(this);
      this.thisWindow.addEventListener('message', boundHandler, true);
      this.unregisterFunction = () => this.thisWindow.removeEventListener('message', boundHandler, true);
    }
  }

  public stopListening(): void {
    // Stop listening if we have started listening
    if (this.unregisterFunction) {
      this.unregisterFunction();
      this.unregisterFunction = undefined;
    }
  }

  public setInitializeMessageHandler(handler?: InitializeMessageHandler): void {
    this.initializeMessageHandler = handler;
  }

  public setCommandResponseMessageHandler(handler?: CommandResponseMessageHandler): void {
    this.commandResponseMessageHandler = handler;
  }

  public setCommandMessageHandler(handler?: CommandMessageHandler): void {
    this.commandMessageHandler = handler;
  }

  public setNotificationMessageHandler(handler?: NotificationMessageHandler): void {
    this.notificationMessageHandler = handler;
  }

  public setHandshakeMessageHandler(handler?: HandshakeMessageHandler): void {
    this.handshakeMessageHandler = handler;
  }

  ///// MessageDispatcher Implementation

  /**
   * @param apiVersion api-internal-contract-js version (exported in JsApiInternalConntract)
   * @param crossFrameVersion crossframe messaging version (exported in JsApiInternalConntract)
   * @param options additional options that can be passed at initialization (information about the version of
   *                external being used for example)
   */
  public prepareInitializationMessage(
    apiVersion: VersionNumber, crossFrameVersion: VersionNumber, options?: InitializationOptions): PreparedMessage {
    const message: InitializeMessage = {
      msgGuid: guid.raw(),
      msgType: MessageType.Initialize,
      crossFrameVersion: crossFrameVersion,
      apiVersion: apiVersion,
      options: options
    };

    return this.prepareMessage(message);
  }

  public prepareCommandMessage(verbId: VerbId, parameters: ExecuteParameters): PreparedMessage {
    const message: CommandMessage = {
      msgGuid: guid.raw(),
      msgType: MessageType.Command,
      verbId: verbId,
      parameters: parameters
    };

    return this.prepareMessage(message);
  }

  public prepareCommandResponseMessage(commandGuid: string, data: Model | undefined, error: Model | undefined): PreparedMessage {
    const message: CommandResponseMessage = {
      msgGuid: guid.raw(),
      msgType: MessageType.CommandResponse,
      commandGuid: commandGuid,
      data: data,
      error: error
    };

    if (error) {
      // stringify error object to remove unserializable fields like functions and prevent serialization errors
      message.error = JSON.parse(JSON.stringify(error));
    }

    return this.prepareMessage(message);
  }

  public prepareNotificationMessage(notificationId: NotificationId, data: Model): PreparedMessage {
    const message: NotificationMessage = {
      msgGuid: guid.raw(),
      msgType: MessageType.Notification,
      notificationId: notificationId,
      data: data
    };

    return this.prepareMessage(message);
  }

  public prepareAckMessage(): PreparedMessage {
    const message: HandshakeMessage = {
      msgGuid: guid.raw(),
      msgType: MessageType.Ack,
      platformVersion: INTERNAL_CONTRACT_VERSION
    };

    return this.prepareMessage(message);
  }

  /**
   * Prepares a pending message for sending and returns the prepared message
   *
   * @param msg The message to be sent to this.otherWindow
   * @returns The prepared message
   */
  private prepareMessage(msg: Message): PreparedMessage {
    if (!this.otherWindow || !this.otherWindowOrigin) {
      throw 'Other window not initialized, cannot dispatch messages';
    }

    const preparedMessage = new CrossFramePreparedMessage(msg, this.otherWindow, this.otherWindowOrigin);
    return preparedMessage;
  }

  /**
   * Called when a message is received. Does some validation of the message, and then
   * calls an appropriate message handler if one is defined
   *
   * @param event The incoming MessageEvent
   */
  private onMessageReceived(event: MessageEvent): void {

    // If we have an otherWindow defined, make sure the message is coming from there
    if (this.otherWindow && event.source !== this.otherWindow) {
      return;
    }

    // Do some validation on event.data to make sure that we have received a real message
    if (!event.data) {
      return;
    }

    const message = event.data;
    if (!isMessage(message)) {
      return;
    }

    // Check the declared message type, validate the message, and call an appropriate hander if one exists
    switch (message.msgType) {
      case MessageType.Initialize: {
        if (!isInitMessage(message) || !this.initializeMessageHandler) {
          return;
        }

        this.initializeMessageHandler(message, event.source);
        break;
      }
      case MessageType.CommandResponse: {
        if (!isCommandResponseMessage(message) || !this.commandResponseMessageHandler) {
          return;
        }

        this.commandResponseMessageHandler(message, event.source);
        break;
      }
      case MessageType.Command: {
        if (!isCommandMessage(message) || !this.commandMessageHandler) {
          return;
        }

        this.commandMessageHandler(message, event.source);
        break;
      }
      case MessageType.Notification: {
        if (!isNotificationMessage(message) || !this.notificationMessageHandler) {
          return;
        }

        this.notificationMessageHandler(message, event.source);
        break;
      }
      case MessageType.Handshake: {
        if (!isHandshakeMessage(message) || !this.handshakeMessageHandler) {
          return;
        }

        this.handshakeMessageHandler(message, event.source);
        break;
      }
      default:
      // Just ignore this since we don't know how to handle the message type
    }
  }

  public setOtherWindow(otherWindow: Window): void {
    this.otherWindow = otherWindow;
  }

  public setOtherWindowOrigin(origin: string): void {
    this.otherWindowOrigin = origin;
  }
}
