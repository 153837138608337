import { SelectionUpdateType, FilterUpdateType, FilterNullOption, IncludeDataValuesOption } from '@tableau/api-external-contract-js';
import * as Contract from '@tableau/api-external-contract-js';
import {
  DataSchema,
  DataSource as DataSourceInfo,
  VisualId,
  WorksheetDataSourceInfo
} from '@tableau/api-internal-contract-js';

import { DataSource } from '../DataSource';

import { DataSourceImpl } from './DataSourceImpl';
import { SheetImpl } from './SheetImpl';
import { SheetInfoImpl } from './SheetInfoImpl';

import { DataSourceService } from '../Services/DataSourceService';
import { FilterService } from '../Services/FilterService';
import { GetDataService, GetDataType } from '../Services/GetDataService';
import { SelectionService } from '../Services/SelectionService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { ErrorHelpers } from '../Utils/ErrorHelpers';
import { LogicalTable } from '../LogicalTable';
import { TableauError } from '../TableauError';
import { DashboardImpl } from './DashboardImpl';

export class WorksheetImpl extends SheetImpl {
  public constructor(sheetInfoImpl: SheetInfoImpl,
    private _visualId: VisualId,
    private _parentDashboardImpl?: DashboardImpl,
    _registryId?: number) {
    super(sheetInfoImpl, _registryId);
  }

  public get parentDashboard(): DashboardImpl {
    if (this._parentDashboardImpl) {
      return this._parentDashboardImpl;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError,
      `ParentDashoard not implemented`);
  }

  public hasParentDashboard(): boolean {
    return !!this._parentDashboardImpl;
  }

  public get visualId(): VisualId {
    return this._visualId;
  }

  public applyFilterAsync(
    fieldName: string, values: Array<string>, updateType: FilterUpdateType, options: Contract.FilterOptions): Promise<string> {
    ErrorHelpers.verifyEnumValue<FilterUpdateType>(updateType, FilterUpdateType, 'FilterUpdateType');

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.applyFilterAsync(this.visualId, fieldName, values, updateType, options);
  }

  public applyRangeFilterAsync(fieldName: string, filterOptions: Contract.RangeFilterOptions): Promise<string> {
    ErrorHelpers.verifyParameter(fieldName, 'fieldName');
    ErrorHelpers.verifyParameter(filterOptions, 'filterOptions');

    if (filterOptions.nullOption) {
      ErrorHelpers.verifyEnumValue<FilterNullOption>(filterOptions.nullOption, FilterNullOption, 'FilterNullOption');
    } else {
      ErrorHelpers.verifyRangeParamType(filterOptions.min, filterOptions.max);
    }

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.applyRangeFilterAsync(this.visualId, fieldName, filterOptions);
  }

  public clearFilterAsync(fieldName: string): Promise<string> {
    ErrorHelpers.verifyParameter(fieldName, 'fieldName');

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.clearFilterAsync(this.visualId, fieldName);
  }

  public getDataSourcesAsync(): Promise<Array<Contract.DataSource>> {
    const service = ApiServiceRegistry.get(this._registryId).getService<DataSourceService>(ServiceNames.DataSourceService);

    return service.getDataSourcesAsync(this.visualId).then<Array<Contract.DataSource>>(result => {
      const dataSchema: DataSchema = result as DataSchema;
      const worksheetDataSourceInfo: WorksheetDataSourceInfo = dataSchema.worksheetDataSchemaMap[this.name];

      const dataSources: Array<Contract.DataSource> = [];

      // First, add the primary datasource.  By convention, it comes first in the returned array.
      const primaryId: string = worksheetDataSourceInfo.primaryDataSource;
      dataSources.push(this.createDataSourceFromInfo(dataSchema.dataSources[primaryId]));

      // Then, loop through any secondary data sources and add them.
      for (const secondaryId of worksheetDataSourceInfo.referencedDataSourceList) {
        if (secondaryId !== primaryId) {
          dataSources.push(this.createDataSourceFromInfo(dataSchema.dataSources[secondaryId]));
        }
      }

      return dataSources;
    });
  }

  public getFiltersAsync(): Promise<Array<Contract.Filter>> {
    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.getFiltersAsync(this.visualId);
  }

  public getSelectedMarksAsync(): Promise<Contract.MarksCollection> {
    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    return service.getSelectedMarksAsync(this.visualId);
  }

  public getHighlightedMarksAsync(): Promise<Contract.MarksCollection> {
    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    return service.getHighlightedMarksAsync(this.visualId);
  }

  public getSummaryDataAsync(options: Contract.GetSummaryDataOptions): Promise<Contract.DataTable> {
    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    options = options || {};

    return service.getUnderlyingDataAsync(
      this.visualId,
      GetDataType.Summary,
      !!options.ignoreAliases,
      !!options.ignoreSelection,
      true,
      options.columnsToIncludeById || [],
      options.maxRows || 0,
      options.includeDataValuesOption || IncludeDataValuesOption.AllValues);
  }

  public getSummaryColumnsInfoAsync(): Promise<Array<Contract.Column>> {
    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    return service.getSummaryColumnsInfoAsync(this.visualId);
  }

  public getUnderlyingDataAsync(options: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    options = options || {};
    return service.getUnderlyingDataAsync(
      this.visualId,
      GetDataType.Underlying,
      !!options.ignoreAliases,
      !!options.ignoreSelection,
      !!options.includeAllColumns,
      options.columnsToIncludeById || [],
      options.maxRows || 0,
      options.includeDataValuesOption || IncludeDataValuesOption.AllValues);
  }

  public getUnderlyingTablesAsync(): Promise<Array<Contract.LogicalTable>> {
    const service = ApiServiceRegistry.get(this._registryId).getService<DataSourceService>(ServiceNames.DataSourceService);
    return service.getUnderlyingTablesAsync(this.visualId).then<Array<Contract.LogicalTable>>(logicalTableInfos => {
      return logicalTableInfos.map(logicalTableInfo => new LogicalTable(logicalTableInfo));
    });
  }

  public getUnderlyingTableDataAsync(logicalTableId: string, options?: Contract.GetUnderlyingDataOptions):
    Promise<Contract.DataTable> {
    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    options = options || {};
    return service.getUnderlyingTableDataAsync(
      this.visualId,
      logicalTableId,
      !!options.ignoreAliases,
      !!options.ignoreSelection,
      !!options.includeAllColumns,
      options.columnsToIncludeById || [],
      options.maxRows || 0,
      options.includeDataValuesOption || IncludeDataValuesOption.AllValues);
  }

  public clearSelectedMarksAsync(): Promise<void> {
    const service = ApiServiceRegistry.get(this._registryId).getService<SelectionService>(ServiceNames.Selection);
    return service.clearSelectedMarksAsync(this.visualId);
  }

  public selectMarksByValueAsync(selections: Array<Contract.SelectionCriteria>,
    selectionUpdateType: SelectionUpdateType): Promise<void> {
    ErrorHelpers.verifyParameter(selections, 'fieldName');
    ErrorHelpers.verifyEnumValue<SelectionUpdateType>(selectionUpdateType, SelectionUpdateType, 'SelectionUpdateType');

    const service = ApiServiceRegistry.get(this._registryId).getService<SelectionService>(ServiceNames.Selection);
    return service.selectMarksByValueAsync(this.visualId, selections, selectionUpdateType);
  }

  public selectMarksByIdAsync(selections: Array<Contract.MarkInfo>,
    selectionUpdateType: SelectionUpdateType): Promise<void> {
    ErrorHelpers.verifyParameter(selections, 'fieldName');
    ErrorHelpers.verifyEnumValue<SelectionUpdateType>(selectionUpdateType, SelectionUpdateType, 'SelectionUpdateType');

    const service = ApiServiceRegistry.get(this._registryId).getService<SelectionService>(ServiceNames.Selection);
    return service.selectMarksByIdAsync(this.visualId, selections, selectionUpdateType);
  }

  private createDataSourceFromInfo(dataSourceInfo: DataSourceInfo): Contract.DataSource {
    const dataSourceImpl = new DataSourceImpl(dataSourceInfo);
    const dataSource = new DataSource(dataSourceImpl);
    dataSourceImpl.initializeWithPublicInterfaces(dataSource);
    return dataSource;
  }
}
