import { InternalContractVersionConverter } from './InternalContractVersionConverter';
import { ExecuteResponse, Notification, VerbId, ExecuteParameters } from '../JsApiInternalContract';

// tslint:disable:no-any

/**
 * This version converter doesn't actually do anything but is useful for testing or when we have
 * a matching platform and internal version number
*/
export class IdentityVersionConverter implements InternalContractVersionConverter {
  public upgradeExecuteCall(verb: any, parameters: any): { verb: VerbId; parameters: ExecuteParameters; } {
    return {
      verb: verb as VerbId,
      parameters: parameters as ExecuteParameters
    };
  }

  public downgradeExecuteReturn(executeResponse: ExecuteResponse): ExecuteResponse {
    return executeResponse;
  }

  public downgradeNotification(notification: Notification): Notification {
    return notification;
  }
}
