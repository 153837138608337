import * as Contract from '@tableau/api-external-contract-js';

export class Size implements Contract.Size {
  public constructor(private _height: number, private _width: number) { }

  public get height(): number {
    return this._height;
  }

  public get width(): number {
    return this._width;
  }
}
