import * as Contract from '@tableau/api-external-contract-js';
import * as InternalContract from '@tableau/api-internal-contract-js';

import { FieldImpl } from './FieldImpl';

import { ConnectionSummary } from '../ConnectionSummary';
import { Field } from '../Field';
import { TableSummary } from '../TableSummary';

import { DataSourceService } from '../Services/DataSourceService';
import { GetDataService } from '../Services/GetDataService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { ErrorHelpers } from '../Utils/ErrorHelpers';
import { LogicalTable } from '../LogicalTable';
import { IncludeDataValuesOption } from '@tableau/api-external-contract-js';

export class DataSourceImpl {
  private _fields: Array<Field>;

  public constructor(private _dataSourceInfo: InternalContract.DataSource, private _registryId?: number) {
    this._fields = _dataSourceInfo.fields.map(fieldModel => {
      const fieldImpl = new FieldImpl(fieldModel, this);
      return new Field(fieldImpl);
    });
  }

  public get name(): string {
    return this._dataSourceInfo.name;
  }

  public get id(): string {
    return this._dataSourceInfo.id;
  }

  public get extractUpdateTime(): string | undefined {
    return this._dataSourceInfo.extractUpdateTime;
  }

  public get fields(): Array<Contract.Field> {
    return this._fields;
  }

  public get isExtract(): boolean {
    return this._dataSourceInfo.isExtract;
  }

  public get isPublished(): boolean | undefined {
    return this._dataSourceInfo.isPublished;
  }

  public publishedUrl(): string | undefined {
    return this._dataSourceInfo.publishedUrl;
  }

  public refreshAsync(): Promise<void> {
    const dataSourceService = ApiServiceRegistry.get(this._registryId).getService<DataSourceService>(
      ServiceNames.DataSourceService);

    return dataSourceService.refreshAsync(this._dataSourceInfo.id);
  }

  public getConnectionSummariesAsync(): Promise<Contract.ConnectionSummary[]> {
    const dataSourceService = ApiServiceRegistry.get(this._registryId).getService<DataSourceService>(
      ServiceNames.DataSourceService);

    return dataSourceService.getConnectionSummariesAsync(this._dataSourceInfo.id).then<Contract.ConnectionSummary[]>(summaries => {
      return summaries.map(summary => new ConnectionSummary(summary));
    });
  }

  public getActiveTablesAsync(): Promise<Array<Contract.TableSummary>> {
    const dataSourceService = ApiServiceRegistry.get(this._registryId).getService<DataSourceService>(
      ServiceNames.DataSourceService);

    return dataSourceService.getActiveTablesAsync(this._dataSourceInfo.id).then<Array<Contract.TableSummary>>(tableInfos => {
      return tableInfos.map(tableInfo => new TableSummary(tableInfo));
    });
  }

  public getUnderlyingDataAsync(options?: Contract.DataSourceUnderlyingDataOptions):
    Promise<Contract.DataTable> {

    const getDataService = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    options = options || {};

    return getDataService.getDataSourceDataAsync(
      this.id,
      !!options.ignoreAliases,
      options.maxRows || 0,       // 0 and [] are defaults
      options.columnsToInclude || [],
      options.columnsToIncludeById || [],
      options.includeDataValuesOption || IncludeDataValuesOption.AllValues);
  }

  public getLogicalTableDataAsync(logicalTableId: string, options?: Contract.DataSourceUnderlyingDataOptions):
    Promise<Contract.DataTable> {
    const getDataService = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    options = options || {};

    return getDataService.getLogicalTableDataAsync(
      this.id,
      logicalTableId,
      !!options.ignoreAliases,
      options.maxRows || 0,       // 0 and [] are defaults
      options.columnsToInclude || [],
      options.columnsToIncludeById || [],
      options.includeDataValuesOption || IncludeDataValuesOption.AllValues);
  }

  public initializeWithPublicInterfaces(dataSource: Contract.DataSource): void {
    ErrorHelpers.verifyInternalValue(dataSource, 'dataSource');

    this._fields = this._dataSourceInfo.fields.map(fieldModel => {
      const fieldImpl = new FieldImpl(fieldModel, dataSource);
      return new Field(fieldImpl);
    });
  }

  public getLogicalTablesAsync(): Promise<Array<Contract.LogicalTable>> {
    const dataSourceService = ApiServiceRegistry.get(this._registryId).getService<DataSourceService>(
      ServiceNames.DataSourceService);

    return dataSourceService.getLogicalTablesAsync(this.id).then<Array<Contract.LogicalTable>>(logicalTableInfos => {
      return logicalTableInfos.map(logicalTableInfo => new LogicalTable(logicalTableInfo));
    });
  }
}
