/**
 * This is your main. This is where you re-export everything you want to be publicly available.
 *
 * The build enforces that the file has the same name as the global variable that is exported.
 */

// The following polyfills are needed for IE11
import 'core-js/es/object/assign';
import 'core-js/es/number/is-integer';
import 'core-js/es/number/is-nan';

export * from './contract/Enums';
export * from './contract/DataTypeConverter';
export * from './contract/Models';
export * from './contract/Notifications';
export * from './contract/Parameters';
export * from './contract/Verbs';
export * from './interface/InitializationOptions';
export * from './interface/InternalApiDispatcher';
export * from './interface/VersionNumber';
export * from './interface/VqlInterface';
export * from './versioning/VersionConverterFactory';
export * from './versioning/external/ExternalVersionConverterFactory';
export * from './versioning/InternalContractVersionConverter';
export * from './versioning/external/ExternalContractVersionConverter';
export * from './versioning/external/ExternalIdentityVersionConverter';

// These are the exports from the messaging stuff

export * from './messaging/CrossFrameMessenger';
export * from './messaging/interface/MessageDispatcher';
export * from './messaging/interface/MessageListener';
export * from './messaging/interface/MessageTypes';
export * from './messaging/interface/Messenger';
export * from './messaging/interface/PreparedMessage';

// Export the version of the internal contract.
//  This is pulled from the package.json file.
// NOTE: semver package is preferred here, but requires transpiler
//  like babel as part of our webpack config.
// import * as semver from 'semver';
import * as packJson from '../package.json';
import { InternalTableauError } from './contract/Models';

let versionParts;
try {
  versionParts = packJson.version.split('.').map(p => parseInt(p, 10));
} catch (e) {
  console.error(e);
  throw new Error(`Unable to parse internal contract version ${packJson.version}`) as InternalTableauError;
}

export const INTERNAL_CONTRACT_VERSION = {
  major: versionParts[0],
  minor: versionParts[1],
  fix: versionParts[2],
};

// Export the version number of messaging for consumers to use.
// Be very careful making any updates to this contract which break version compatibility.
export const MESSAGING_VERSION = {
  major: 1,
  minor: 0,
  fix: 0
};
